import React, { Component } from 'react';
import * as Constants from '../global/constants';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import {Helmet} from "react-helmet";
import ReCaptcha from 'react-google-recaptcha';
import { isEmptyVariable } from '../global/commonFunctions';
import * as Api from '../global/apiendpoints';

class Home extends Component {
    constructor(props) {
    super(props);
    this.state = {
        username: '',
        password: '',
        submitted: false,
        isLoggedIn:false,
        loggedInUserName:'',
        loggedInUserId:'',
        loggedInUserType:'',
        loggedInUserEmail:'',
        loggedInUserAccessToken:'',
        errors:{},
        isVerified:false,
        showLoader: false,
    };
}

    componentDidMount()
    {
        if (this.captchaDemo) {
            // console.log("started, just a second...");
            this.captchaDemo.reset();
        }
        if(localStorage.getItem('loggedInDetailsReactRepoAdmin'))
        {
            this.props.history.push('/dashboard');
        }
    }

    onLoadRecaptcha = () => {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!  
        console.log(recaptchaToken, "<= your recaptcha token");
        if(recaptchaToken)
        {
            this.setState({ isVerified: true });
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    // login()
    // {
    //     document.getElementById("login-div").style.display="block";
    // }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ 
            submitted: true,
            showLoader: true,
         });
        const { username, password } = this.state;
        
        if(!this.validateForm())
        {
            fetch(Api.BACKEND_LOGIN,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({email:username, password:password})
            })
            .then(response => { return response.json(); } )
            .then(data =>
                  {
                    if(data.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                        this.setState(
                            { 
                                showLoader: false,
                                isLoggedIn: true,
                                loggedInUserName: data.data.name,
                                loggedInUserEmail: data.data.email,
                                loggedInUserAccessToken: data.data.accessToken,
                                loggedInProPic:data.data.proPicPath,
                            },()=>{
                                let parameters = {
                                    loggedInUserEmail:this.state.loggedInUserEmail,
                                    loggedInUserAccessToken:this.state.loggedInUserAccessToken,
                                    loggedInProPic:this.state.loggedInProPic,
                                    loggedInUserName:this.state.loggedInUserName,
                                };
                                localStorage.clear();
                                localStorage.setItem('loggedInDetailsReactRepoAdmin', JSON.stringify(parameters));
                                this.props.history.push('/dashboard');
                            });
                    }
                    else
                    {
                        let errors = {};
                        errors['respMsg'] = "Invalid Login!";
                        this.setState({
                            errors: errors,
                        });
                    }    
                });
        }
        else
        {
            this.setState({
                showLoader: false,
            });
        }
        
    }
    
    validateForm()
    {
        var error_flag = false;
        let errors = {};
        if(isEmptyVariable(this.state.username))
        {
            error_flag = true;
            errors['username'] = "Please enter username!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        else
        {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if(!pattern.test(this.state.username)) {
                error_flag = true;
                errors["username"] = "Please enter valid email ID!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),Constants.WRNG_MSG_TIMEOUT);
            }
        }
        
        if(isEmptyVariable(this.state.password))
        {
            error_flag = true;
            errors['password'] = "Please enter password!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }

        if(!this.state.isVerified)
        {
            error_flag = true;
            errors['verified'] = "Please verify to continue!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        
        this.setState({
        errors: errors
      });
        return error_flag;
    }

    render() {
        const { username, password } = this.state;
        return (
            <Router>
                <Helmet>
                    <script src="https://www.google.com/recaptcha/api.js" async defer></script>
                </Helmet>
                <div className="app no-padding no-footer layout-static">
                    <div className="session-panel">
                        <div className="session">
                            <div className="session-content">
                                <div className="card card-block form-layout">
                                    <div id="login-div">
                                        {
                                            this.state.showLoader && 
                                            <div id="wait" className="loader-login">
                                                <img src="/assets/images/spinner.GIF" width="64" height="64" />
                                            </div>	
                                        }
                                        
                                        <form action="javascript:void(0);" onSubmit={this.handleSubmit} id="login_form">
                                            <div className="text-xs-center m-b-1 m-t-1">
                                                <img src="/assets/images/logo-light-bg.png" style={{height:60, width:'auto'}} alt="" className="m-b-1"/>
                                            </div>
                                            
                                            <fieldset className="form-group">
                                                <label for="username">
                                                Enter your email
                                                </label>
                                                <div className="input-group login-input">
                                                <div className="input-group-addon"><i className="material-icons">account_circle</i></div>
                                                <input type="text" className="form-control form-control-md" name="username" onChange={this.handleChange} value={username}/>
                                                </div>
                                                {!isEmptyVariable(this.state.errors.username) && 
                                                    <div className="err_msg">{this.state.errors.username}</div>
                                                }
                                            
                                            </fieldset>				
                                            <fieldset className="form-group">
                                                <label for="password">
                                                Enter your password
                                                </label>
                                                <div className="input-group login-input">
                                                <div className="input-group-addon"><i className="material-icons"><i className="material-icons">lock</i></i></div>
                                                <input type="password" className="form-control form-control-md" name="password" onChange={this.handleChange} value={password}/>
                                                </div>
                                                {!isEmptyVariable(this.state.errors.password) && 
                                                    <div className="err_msg">{this.state.errors.password}</div>
                                                }
                                            </fieldset>
                                            <div className="recaptcha-container">
                                                {/* <ReCaptcha
                                                    ref={(el) => {this.captchaDemo = el;}}
                                                    size="normal"
                                                    data-theme="dark"            
                                                    render="explicit"
                                                    sitekey={Constants.RECAPTCHA_SITE_KEY}
                                                    onloadCallback={this.onLoadRecaptcha}
                                                    verifyCallback={this.verifyCallback}
                                                    expiredCallback={this.expireRecaptcha}
                                                /> */}
                                                <ReCaptcha
                                                    sitekey={Constants.RECAPTCHA_SITE_KEY}
                                                    onChange={this.verifyCallback}
                                                />
                                                {!isEmptyVariable(this.state.errors.verified) && 
                                                    <div className="err_msg">{this.state.errors.verified}</div>
                                                }
                                            </div>
                                           
                                            <button className="btn btn-primary btn-block btn-md" type="submit">
                                                Login
                                            </button>
                                            {!isEmptyVariable(this.state.errors.respMsg) && 
                                                <div className="err_msg" id="resp-msg">{this.state.errors.respMsg}</div>
                                            }
                                            <footer className="text-xs-center p-y-1">
                                            </footer>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Router>
    );
  }
}
export default Home;
