import React, { Component } from 'react';
import * as Constants from '../global/constants';
import * as Api from '../global/apiendpoints';
import {getLocalStorageVariables,isEmptyArray,isEmptyVariable} from "../global/commonFunctions"
import { matchPath,withRouter } from 'react-router-dom';
import {
    BrowserRouter as Router
} from 'react-router-dom';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedInDetailsReactRepoAdmin: {},
            categories:[],
            hireUnreadCount:0,
        };
    }
    
    componentDidMount() {
        // IT should be inside here. Don't change it. Only for this page.
        const userDetails =  getLocalStorageVariables();
        
        Promise.all([fetch(Api.GET_CATEGORIES,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(
                { 
                    email:userDetails.email,
                    accessToken:userDetails.accessToken
                })
            }),
            fetch(Api.GET_HIRES,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(
                    { 
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        status:Constants.HIRE_STATUS_UNREAD
                    })
                }),
        ])
        .then(([categoryRes,hireUsRes]) => { 
            return Promise.all([categoryRes.json(),hireUsRes.json()]) 
        })
        .then(([categoryRes,hireUsRes]) => {
            let categories = [];
            let hireUsUnreadCount = 0;
            let urlPath = this.props.history.location.pathname;

            if(categoryRes.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                categoryRes.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                localStorage.clear();
                window.location = "/";
            }else if(categoryRes.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                categories = categoryRes.data.result;
            }else{
                //TODO ERROR HANDLING
            }

            if(hireUsRes.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                hireUsRes.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                localStorage.clear();
                window.location = "/";
            }else if(hireUsRes.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                hireUsUnreadCount = hireUsRes.data.count;
            }   

            //lets check if any category is selected from sidebar
            let showTemplateDropdown = false;
            categories.forEach(item=>{
                let categoryNamePath = "/category/"+item.categoryMasterId
                if(matchPath(this.props.history.location.pathname, {path: [categoryNamePath]}) ||
                matchPath(this.props.history.location.pathname, { path: ["/uploadtemplate","/edittemplate"] })){
                    showTemplateDropdown=true;
                }
            })

            this.setState({
                categories:categories,
                hireUnreadCount:hireUsUnreadCount,
                showTemplateDropdown:showTemplateDropdown,
                showUserDropdown:matchPath(this.props.history.location.pathname, { path: ["/appusers","/admins","/userdetails"]})
            });
        });
    }

    templateDropdownClick = () => {
        this.setState({
            showTemplateDropdown:!this.state.showTemplateDropdown
        })
    }

    usersDropdownClicked = () => {
        this.setState({
            showUserDropdown:!this.state.showUserDropdown
        })
    }

    render() {
        const userDetails =  getLocalStorageVariables();
        return(
            <Router>
                <div className="off-canvas-overlay" data-toggle="sidebar"></div>  
                <div className="sidebar-panel">
                    <div className="brand">
                        <a href="javascript:;" data-toggle="sidebar" className="toggle-offscreen hidden-lg-up">
                            <i className="material-icons">menu</i>
                        </a>
                        <a className="brand-logo" href="/dashboard">
                        <img src="/assets/images/logo-dark-bg.png"/>
                        </a>
                    </div>
                    <div className="nav-profile dropdown">
                        <a href="javascript:;" className="dropdown-toggle" data-toggle="dropdown">
                            <div className="user-info expanding-hidden">
                                {userDetails.fullname}
                                <small className="sentence-case">{userDetails.email}</small>            
                            </div>
                        </a>
                    </div>

                    <div className="user-image">
                        {
                            !isEmptyVariable(userDetails.profilePic) &&
                            <img src={Constants.BASE_URL_API+ userDetails.profilePic} className="avatar img-circle" alt="user" title="user"/>
                        }

                        {
                            isEmptyVariable(userDetails.profilePic) && 
                            <img src="/assets/images/avatar.png" className="avatar img-circle" alt="user" title="user"/>
                        }
                    </div>
                    <nav>
                        <p className="nav-title">NAVIGATION</p>
                        <ul className="nav">
                            <li 
                                className={matchPath(this.props.history.location.pathname, { path: ["/dashboard"] }) ? "active-li" : ""}>
                                <a href="/dashboard">
                                    <i className="material-icons">dashboard</i>
                                    <span>Dashboard</span>
                                </a>
                            </li>

                            <li 
                            className={matchPath(this.props.history.location.pathname, { path: ["/orders","/orderdetails"] }) ? "active-li" : ""}>
                                <a href="/orders">
                                    <i className="material-icons">shopping_cart</i>
                                    <span>Orders</span>
                                </a>
                            </li>

                            <li 
                            className={matchPath(this.props.history.location.pathname, { path: ["/categories"] }) ? "active-li" : ""}>
                                <a href="/categories">
                                    <i className="material-icons">list</i>
                                    <span>Categories</span>
                                </a>
                            </li>

                            <li className={this.state.showTemplateDropdown?"open":""}>
                                <a href="javascript:;" onClick={this.templateDropdownClick}>
                                    <span className="menu-caret">
                                        <i className="material-icons">arrow_drop_down</i>
                                    </span>
                                    <i className="material-icons">description</i>
                                    <span>Templates</span>
                                </a>
                                <ul className="sub-menu">
                                    <li 
                                    className={matchPath(this.props.history.location.pathname, { path: ["/uploadtemplate","/edittemplate"] }) ? "active-li" : ""}>
                                        <a href="/uploadtemplate">
                                            <span>Upload Template</span>
                                        </a>
                                    </li>
                                    {
                                        !isEmptyArray(this.state.categories) && 
                                        this.state.categories.map((item) => {
                                            // let urlPath = (item.categoryName.replace(/\s-\s/g, ' ').replace(/\s+/g, '-')).toLowerCase();
                                            let urlPath = item.categoryMasterId;
                                            return <li
                                            className={matchPath(this.props.history.location.pathname, { path: ["/category/"+urlPath] }) ? "active-li" : ""}>
                                                <a href={Constants.DEFAULT_CATEGORY_URL+urlPath}>
                                                    <span>{item.categoryName}</span>
                                                </a>
                                            </li>
                                        })
                                    }
                                    
                                </ul>
                            </li>

                            <li className={this.state.showUserDropdown?"open":""}>
                                <a href="javascript:;" onClick={this.usersDropdownClicked}>
                                    <span className="menu-caret">
                                        <i className="material-icons">arrow_drop_down</i>
                                    </span>
                                    <i className="material-icons">people</i>
                                    <span>Users</span>
                                </a>
                                <ul className="sub-menu">
                                    <li
                                    className={matchPath(this.props.history.location.pathname, { path: ["/appusers","/userdetails"] }) ? "active-li" : ""}>
                                        <a href="/appusers">
                                            <span>App Users</span>
                                        </a>
                                    </li>
                                    <li
                                    className={matchPath(this.props.history.location.pathname, { path: ["/admins"] }) ? "active-li" : ""}>
                                        <a href="/admins">
                                            <span>Admins</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li 
                            className={matchPath(this.props.history.location.pathname, { path: ["/hireenquiries","/hiredetails"] }) ? "active-li" : ""}>
                                <a href="/hireenquiries">
                                    <i className="material-icons">message</i>
                                    <span>Hire Enquiries {this.state.hireUnreadCount > 0 && " ("+this.state.hireUnreadCount+")"}</span>
                                </a>
                            </li>
                            <li 
                            className={matchPath(this.props.history.location.pathname, { path: ["/profile"] }) ? "active-li" : ""}>
                                <a href="/profile">
                                    <i className="material-icons">perm_identity</i>
                                    <span>Profile</span>
                                </a>
                            </li>
                            <li>
                                <a href="/logout">
                                    <i className="material-icons">power_settings_new</i>
                                    <span>Logout</span>
                                </a>
                            </li>
                            <li><hr/></li>
                        </ul>
                    </nav>
                </div>
            </Router>
        );
    }
}
export default withRouter(Sidebar);