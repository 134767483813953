import React, { Component } from 'react';
import * as Constants from '../../global/constants';
import {Modal} from 'react-bootstrap';
import {getLocalStorageVariables,
    isEmptyVariable} from '../../global/commonFunctions';
import * as Api from '../../global/apiendpoints';

const userDetails  = getLocalStorageVariables();

class AddCategoryDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            categoryName:"",
            s3BucketPath:"",
            seoTitle:"",
            seoDescription:"",
            formErrors:{},
            submitClickedAtleastOnce:false,
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateDialog){
            if(this.props.isEdit){
                this.setState({
                    categoryName:this.props.editItem.categoryName,
                    s3BucketPath:this.props.editItem.s3BucketPath,
                    seoTitle:this.props.editItem.seoTitle,
                    seoDescription:this.props.editItem.seoDescription,
                    formErrors:{},
                    submitClickedAtleastOnce:false,
                })
            }else{
                this.setState({
                    s3BucketPath:"",
                    categoryName:"",
                    seoTitle:"",
                    seoDescription:"",
                    formErrors:{},

                })
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.handleCreateCategory();
        }
    }
    
    handleCreateDialogClose = () =>{
        this.props.handleCreateDialogClose(false);
    }

    handleCreateCategory = () => {
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.categoryName)){
            isError = true;
            FormErrorsTemp['categoryName'] = "Please enter category name";
        }
        if(isEmptyVariable(this.state.s3BucketPath)){
            isError = true;
            FormErrorsTemp['s3BucketPath'] = "Please enter s3 bucket path";
        }
        if(isEmptyVariable(this.state.seoTitle)){
            isError = true;
            FormErrorsTemp['seoTitle'] = "Please enter SEO Title";
        }
        if(isEmptyVariable(this.state.seoDescription)){
            isError = true;
            FormErrorsTemp['seoDescription'] = "Please enter SEO Description";
        }
        if(!isEmptyVariable(this.state.s3BucketPath)){
            var pattern = new RegExp(/^[a-z]+$/);
            if (!pattern.test(this.state.s3BucketPath)){
                isError = true;
                FormErrorsTemp['s3BucketPath'] = "Please enter only lower case alphabets";
            }
        }
        
        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
            },()=>{
                setTimeout(() =>{
                    this.setState({formErrors:{}});
                },Constants.WRNG_MSG_TIMEOUT);
            });

        }else{
            let url = "";
            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                categoryName : this.state.categoryName,
                s3BucketPath : this.state.s3BucketPath,
                seoTitle : this.state.seoTitle,
                seoDescription : this.state.seoDescription,
            }

            if(this.props.isEdit){
                url = Api.EDIT_CATEGORY;
                postParam.categoryMasterId = this.props.editItem.categoryMasterId
            }else{
                url = Api.ADD_CATEGORY;
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(data.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState({
                        s3BucketPath:"",
                        categoryName:"",
                        seoTitle:"",
                        seoDescription:"",
                        formErrors:{},
                    },()=>{
                        this.props.handleCreateDialogClose(true);
                    });
                }else{
                    // TODO Error Handling
                }
            });
        }
    }

    render() {
        return(
            <Modal style={{opacity:1}} className="custom-dialog custom-dialog-category" show={this.props.showCreateDialog} onHide={this.handleCreateDialogClose}>
                <Modal.Header>
                    <h5>{this.props.isEdit?"Edit Category":"Add New Category"}</h5>
                    <button 
                        onClick={this.handleCreateDialogClose}
                        type="button" data-dismiss="modal">
                        <span className="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    <div className="addnew-modal-form">
                        <div className="addnew-modal-form-group">
                            <p>Category Name*</p>
                            <input type="text" placeholder="Enter Category Name" 
                            name="categoryName" value={this.state.categoryName} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['categoryName']) &&
                                <span className="cm-error">{this.state.formErrors['categoryName']}</span>
                            }
                        </div>
                        <div className="addnew-modal-form-group">
                            <p>SEO Title*</p>
                            <input type="text" placeholder="Enter SEO Title"
                            name="seoTitle" value={this.state.seoTitle} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['seoTitle']) &&
                                <span className="cm-error">{this.state.formErrors['seoTitle']}</span>
                            }
                        </div>
                        <div className="addnew-modal-form-group">
                            <p>SEO Description*</p>
                            <textarea type="text" placeholder="Enter SEO Description"
                            name="seoDescription" value={this.state.seoDescription} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['seoDescription']) &&
                                <span className="cm-error">{this.state.formErrors['seoDescription']}</span>
                            }
                        </div>
                        <div className="addnew-modal-form-group">
                            <p>S3 Bucket Name*</p>
                            <input type="text" placeholder="Enter S3 Bucket Name" onKeyPress={this.onEnterBtnPress}
                            name="s3BucketPath" value={this.state.s3BucketPath} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['s3BucketPath']) &&
                                <span className="cm-error">{this.state.formErrors['s3BucketPath']}</span>
                            }
                        </div>
                    </div>
                    <div className="modal-close-save">
                        <div className="close-save-col">
                            <button onClick={this.handleCreateDialogClose} type="button" className="modal-close">Cancel</button>
                            <button className="modal-save" onClick={this.handleCreateCategory} type="button">Save</button>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default AddCategoryDialog;