import React, { Component } from 'react';
import Home from './components/pagesComponent/home';
import Dashboard from './components/pagesComponent/dashboard';
import Orders from './components/pagesComponent/orderComponents/order';
import Orderdetails from './components/pagesComponent/orderComponents/orderdetails';
import Categories from './components/pagesComponent/categoryComponents/categories';
import Userdetails from './components/pagesComponent/userComponents/userdetails';
import Reacttemplate from './components/pagesComponent/templateComponents/categorytemplate';
import Categorytemplate from './components/pagesComponent/templateComponents/categorytemplate';
import Uploadtemplate from './components/pagesComponent/templateComponents/uploadtemplate';
import Templatedetails from './components/pagesComponent/templateComponents/templatedetails';
import Appusers from './components/pagesComponent/userComponents/userslist';
import Hireenquiries from './components/pagesComponent/hireenquiriesComponents/hireenquiries';
import Hiredetails from './components/pagesComponent/hireenquiriesComponents/hiredetails';
import Profile from './components/pagesComponent/profileComponents/profile';
import Logout from './components/pagesComponent/logout';

import {
    BrowserRouter as Router,
    Route,Redirect
} from 'react-router-dom';

const ProtectedRoute = ({ path, component: Comp }) => {
    return (
        <Route

            path={path}
        
            render={(props) => {
                return localStorage.getItem('loggedInDetailsReactRepoAdmin') ? (
                    <Comp {...props} />
                ) : (
                    <Redirect
                        to={{
                        pathname: "/"}}
                    />
                );
            }}
        />
    );
};

class App extends Component {
    render() {
        return (
            <Router>
                <Route exact path="/" component={Home} />
                <ProtectedRoute exact path="/dashboard" component={Dashboard} />
                <ProtectedRoute exact path="/orders" component={Orders} />
                <ProtectedRoute exact path="/orderdetails" component={Orderdetails} />
                <ProtectedRoute exact path="/reacttemplate" component={Reacttemplate} />
                <ProtectedRoute exact path="/category/:id" component={Categorytemplate} />
                <ProtectedRoute exact path="/uploadtemplate" component={Uploadtemplate} />
                <ProtectedRoute exact path="/templatedetails/:id" component={Templatedetails} />
                <ProtectedRoute exact path="/edittemplate/:id" component={Uploadtemplate} />
                <ProtectedRoute exact path="/appusers" component={Appusers} />
                <ProtectedRoute exact path="/admins" component={Appusers} />
                <ProtectedRoute exact path="/userdetails/:id" component={Userdetails} />
                <ProtectedRoute exact path="/hireenquiries" component={Hireenquiries} />
                <ProtectedRoute exact path="/hiredetails/:id" component={Hiredetails} />
                <ProtectedRoute exact path="/categories" component={Categories} />
                <ProtectedRoute exact path="/profile" component={Profile}/>
                <ProtectedRoute exact path="/logout" component={Logout} />
            </Router>
        );
    }
}

export default App;
