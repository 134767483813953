import React, { Component } from 'react';
import * as Constants from '../../global/constants';
import Sidebar from '../../layoutsComponent/sidebar';
import Pagination from '../uiComponents/pagination';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {
    BrowserRouter as Router
} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import * as Api from '../../global/apiendpoints';
import { isEmptyArray, isEmptyVariable, getLocalStorageVariables, 
    getLocalDateOnlyFromUTC, getUTCDateTimeFromLocal } from "../../global/commonFunctions";

const userDetails = getLocalStorageVariables();

class Categorytemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryMasterId:this.props.match.params.id,
            categoryName:"",
            currentPageNo: 1,
            pageOfItems: [],
            totalCount:0,
            errors:[],

            searchFilterFromDate: '',
            searchFilterToDate: '',
            templateName:'',
            isFree:'',
            isFeatured:'',
            isNew:'',
            status:'',
        };
    }

    componentDidMount(){
        this.getTemplatesList();
    }

    handleSearchFromDate = (e) => {
        this.setState({ searchFilterFromDate: e });
    }

    handleSearchToDate= (e) => {
        this.setState({ searchFilterToDate: e });
    }

    handleSearch = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    search() {
        var error_flag = false;
        let errors = {};
        if (
            isEmptyVariable(this.state.templateName) &&
            isEmptyVariable(this.state.isFree) &&
            isEmptyVariable(this.state.isFeatured) &&
            isEmptyVariable(this.state.isNew) &&
            isEmptyVariable(this.state.status) &&
            isEmptyVariable(this.state.searchFilterFromDate) &&
            isEmptyVariable(this.state.searchFilterToDate) 
        )
        {
            error_flag = true;
            errors['search'] = "Please select atleast one filter!";
            setTimeout(()=>{
                this.setState({errors:{}});
           },Constants.WRNG_MSG_TIMEOUT);  
        }
        if (error_flag) {
            this.setState({
                errors: errors
            });
            return error_flag;
        }
        else {
            this.setState({currentPageNo:1},()=>{
                this.getTemplatesList();
            });
        }
    }

    onEnterBtnPress = (e) => {
        let code = e.keyCode || e.which;
        if(code === 13){
            this.search();
        }
    }

    clearFilter() {
        this.setState({
            searchFilterFromDate: '',
            searchFilterToDate: '',
            templateName:'',
            isFree:'',
            isFeatured:'',
            isNew:'',
            status:''
        },()=>{
            this.getTemplatesList();
        });
        
    }

    getTemplatesList() {
        fetch(Api.GET_TEMPLATES_ADMIN,
        {
            method: "POST",
            mode: 'cors',
            body: new URLSearchParams({ 
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                categoryMasterId:this.state.categoryMasterId,
                pageNo: this.state.currentPageNo, 
                resultsize:Constants.RESULT_SIZE,
                searchFilterFromDate: getUTCDateTimeFromLocal(this.state.searchFilterFromDate,false),
                searchFilterToDate: getUTCDateTimeFromLocal(this.state.searchFilterToDate,true),
                templateName:this.state.templateName,
                isFree:this.state.isFree,
                isFeatured:this.state.isFeatured,
                isNew:this.state.isNew,
                status:this.state.status,
                
            }) 
        })
        .then(response => { return response.json(); })
        .then(res1 => {
            if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                localStorage.clear();
                window.location = "/";
            }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                this.setState({
                    pageOfItems:res1.data.result,
                    totalCount:res1.data.count,
                });
            }else{
                // TODO error handling
            }
        });
    };

    onChangePage = (page) => {
        // alert(page);
        // update state with new page of items
        if(page != this.state.currentPageNo){
           fetch(Api.GET_TEMPLATES_ADMIN,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(
                    {
                        categoryMasterId:this.state.categoryMasterId,
                        pageNo: page, 
                        searchFilterFromDate: getUTCDateTimeFromLocal(this.state.searchFilterFromDate,false),
                        searchFilterToDate: getUTCDateTimeFromLocal(this.state.searchFilterToDate,true),
                        templateName:this.state.templateName,
                        isFree:this.state.isFree,
                        isFeatured:this.state.isFeatured,
                        isNew:this.state.isNew,
                        status:this.state.status,
                        resultsize:Constants.RESULT_SIZE,
                        email:userDetails.email,
                        accessToken:userDetails.accessToken
                    })
            })
            .then(response => { return response.json(); } )
            .then(res1 =>
            {
                if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState({
                        pageOfItems:res1.data.result,
                        totalCount:res1.data.count,
                        currentPageNo:page,
                    });
                }else{
                    // TODO error handling
                }
            });
        }
    }

    render() {
        return (
            <Router>
                <Sidebar />
                <div className="main-panel">
                    <nav className="header navbar">
                        <div className="header-inner">
                            <div className="navbar-item navbar-spacer-right brand hidden-lg-up">
                                <a href="javascript:;" data-toggle="sidebar" className="toggle-offscreen">
                                    <i className="material-icons">menu</i>
                                </a>
                            </div>
                            <a className="navbar-item navbar-spacer-right navbar-heading hidden-md-down" href="javascript:void(0);">
                                <span className="tophead-txt">Templates &gt;&gt; View List</span>
                                <span className="tophead-txt pull-right"></span>
                            </a>
                        </div>
                    </nav>

                    <div className="main-content">
                        <div className="content-view">
                            <div className="card">
                                <div className="sec-t-container m-b-2"><h4 className="card-title">Search Filters</h4></div>
                                <div className="card-block">

                                    <div className="row">
                                        <div className="col-lg-3">
                                            <b>Template Name</b>
                                            <fieldset className="form-group">
                                                <input placeholder="Template Name" className="srh-fld" name="templateName" onChange={this.handleSearch} value={this.state.templateName} onKeyPress={this.onEnterBtnPress}/>
                                            </fieldset>
                                        </div>
                                        <div className="col-lg-3">
                                            <b>Is Free</b>
                                            <fieldset className="form-group">
                                                <select className="srh-fld" name="isFree" onChange={this.handleSearch} value={this.state.isFree}>
                                                    <option value="">--select--</option>
                                                    <option value="Y">Yes</option>
                                                    <option value="N">No</option>
                                                </select>
                                            </fieldset>
                                        </div>
                                        <div className="col-lg-3">
                                            <b>Is Featured</b>
                                            <fieldset className="form-group">
                                                <select className="srh-fld" name="isFeatured" onChange={this.handleSearch} value={this.state.isFeatured}>
                                                    <option value="">--select--</option>
                                                    <option value="Y">Yes</option>
                                                    <option value="N">No</option>
                                                </select>
                                            </fieldset>
                                        </div>
                                        <div className="col-lg-3">
                                            <b>Is New</b>
                                            <fieldset className="form-group">
                                                <select className="srh-fld" name="isNew" onChange={this.handleSearch} value={this.state.isNew}>
                                                    <option value="">--select--</option>
                                                    <option value="Y">Yes</option>
                                                    <option value="N">No</option>
                                                </select>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-lg-3">
                                        <b>Status</b>
                                        <fieldset className="form-group">
                                        <select name="status" className="srh-fld" onChange={this.handleSearch} value={this.state.status}>
                                        <option value="">--select--</option>
                                        <option value={Constants.TEMPLATE_STATUS_ACTIVE}>{Constants.TEMPLATE_STATUS_ACTIVE}</option>
                                        <option value={Constants.TEMPLATE_STATUS_DRAFT}>{Constants.TEMPLATE_STATUS_DRAFT}</option>
                                        <option value={Constants.TEMPLATE_STATUS_INACTIVE}>{Constants.TEMPLATE_STATUS_INACTIVE}</option>
                                        
                                        </select>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-3">
                                            <b>Upload From Date</b>
                                            <DatePicker
                                                name="searchFromDate"
                                                selected={this.state.searchFilterFromDate}
                                                onChange={this.handleSearchFromDate}
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <b>Upload To Date</b>
                                            <DatePicker
                                                name="searchToDate"
                                                selected={this.state.searchFilterToDate}
                                                onChange={this.handleSearchToDate}
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                    <div className="col-lg-3" style={{float:'right'}}>
                                        <b>&nbsp;</b>
                                        <div className="srch-fltr-btns">
                                            <button type="button" className="btn btn-primary" onClick={this.search.bind(this)}><i className="fa fa-search" aria-hidden="true"></i> <span className="filterText">Search</span></button>
                                            <button type="button" className="btn btn-danger" onClick={this.clearFilter.bind(this)}><i className="fa fa-times-circle-o" aria-hidden="true"></i><span className="filterText">&nbsp;Clear</span></button>
                                        </div>
                                    </div>
                                </div>
                                {   
                                    !isEmptyVariable(this.state.errors.search) && 
                                    <span id="error_msg" className="err_msg">{this.state.errors.search}</span>
                                }
                                </div>
                            </div>

                            <div className="card">
                                <div className="sec-t-container m-b-2 addclnt-title"><h4 className="card-title">Templates - List</h4>
                                </div>
                                <div className="card-block">
                                    <div id="clientlist-wait" className="loader-login">
                                        <img src="/assets/images/spinner.GIF" width="64" height="64" />
                                    </div>
                                    <div className="">
                                        <table className="table m-b-0">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th width="25%">Template Name</th>
                                                    <th>Sales</th>
                                                    <th>Uploaded By</th>
                                                    <th>Price</th>
                                                    {/* <th style={{width:'15%'}}>Category</th> */}
                                                    <th>Free</th>
                                                    <th>Sample</th>
                                                    <th>Others</th>
                                                    <th width="5%"></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    !isEmptyArray(this.state.pageOfItems) && 
                                                    this.state.pageOfItems.map(function (item, index) {
                                                        return <tr>
                                                            <td>{((this.state.currentPageNo - 1) * Constants.RESULT_SIZE) + (++index)}</td>
                                                            <td>
                                                                <a href={"/templatedetails/"+item.templateId}>
                                                                    {item.templateName}
                                                                </a>
                                                            <br/>
                                                            <span className="status-span">{item.templateStatus}</span>
                                                            {
                                                                item.isNew === "Y" &&
                                                                <span className="free-span">Free</span>
                                                            }
                                                            {
                                                                item.isFeatured === "Y" &&
                                                                <span className="featured-span">Featured</span>
                                                            }
                                                            </td>
                                                            <td>{item.totalSales}</td>
                                                            <td>
                                                                {item.name}<br />{item.email}
                                                                <br/>
                                                                <strong>Upload Date: </strong>
                                                                {getLocalDateOnlyFromUTC(item.templateCreateDate)}
                                                            </td>
                                                            {
                                                                item.isFree === "N" &&
                                                                <td>
                                                                    <strong>Discounted Price: </strong>{"$"+item.discountedPrice}
                                                                    <br/>
                                                                    <strong>Original Price: </strong>{"$"+item.originalPrice}
                                                                    <br/>
                                                                    <span style={{color:"red"}}>
                                                                    {Math.round(((item.originalPrice - item.discountedPrice)/item.originalPrice)*100)}% off
                                                                    </span>
                                                                </td>
                                                            }
                                                            
                                                            <td>
                                                                {item.isFree === "Y"?"Yes":"No"}
                                                            </td>
                                                            
                                                            <td>
                                                                {item.hasFreeSample === "Y"?"Yes":"No"}
                                                            </td>
                                                            
                                                            {/* <td>{item.categoryName}</td> */}
                                                            <td>
                                                               <strong>Views: </strong>{item.views}<br/>
                                                               <strong>Downloads: </strong>{item.downloads}
                                                            </td>
                                                            <td>
                                                               <a href={"/edittemplate/"+item.templateId} data-tip="Edit">
                                                                    <i className="material-icons">edit</i>
                                                               </a>
                                                               <ReactTooltip />
                                                            </td>
                                                        </tr>;
                                                    }, this)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {
                                (this.state.totalCount > Constants.RESULT_SIZE) && 
                                <Pagination 
                                    totalLength ={this.state.totalCount} 
                                    items={this.state.pageOfItems} 
                                    onChangePage={this.onChangePage} 
                                    currentPageNo = {this.state.currentPageNo} />
                            }
                        </div>
                    </div>
                </div>
            </Router>
        );
    }
}
export default Categorytemplate;