import React, { Component } from 'react';
import * as Constants from '../../global/constants';
import {Modal} from 'react-bootstrap';
import {getLocalStorageVariables,
    isEmptyVariable, isEmptyArray} from '../../global/commonFunctions';
import * as Api from '../../global/apiendpoints';

const userDetails  = getLocalStorageVariables();

class AddCategoryDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            name:"",
            email:"",
            password:"",
            formErrors:{},
            submitClickedAtleastOnce:false,
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateDialog){
            if(this.props.isEdit){
                this.setState({
                    name:this.props.editItem.name,
                    email:this.props.editItem.email,
                    password:this.props.editItem.password,
                    formErrors:{},
                    submitClickedAtleastOnce:false,
                })
            }else{
                this.setState({
                    name:"",
                    email:"",
                    password:"",
                    formErrors:{},
                })
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.handleCreateCategory();
        }
    }
    
    handleCreateDialogClose = () =>{
        this.props.handleCreateDialogClose(false);
    }

    handleCreateCategory = () => {
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.name)){
            isError = true;
            FormErrorsTemp['name'] = "Please enter the name";
        }
        if(isEmptyVariable(this.state.email)){
            isError = true;
            FormErrorsTemp['email'] = "Please enter email";
        }
        if(!this.props.isEdit && isEmptyVariable(this.state.password)){
            isError = true;
            FormErrorsTemp['password'] = "Please enter password";
        }
        
        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
            },()=>{
                setTimeout(() =>{
                    this.setState({formErrors:{}});
                },Constants.WRNG_MSG_TIMEOUT);
            });

        }else{
            let url = "";
            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                name : this.state.name,
                userEmail : this.state.email,
            }

            if(this.props.isEdit){
                url = Api.UPDATE_USER;
                postParam.userId = this.props.editItem.userId
            }else{
                url = Api.CREATE_USER;
                postParam.password = this.state.password
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(data.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState({
                        name:"",
                        email:"",
                        password:"",
                        formErrors:{},
                    },()=>{
                        this.props.handleCreateDialogClose(true);
                    });
                }else{
                    this.setState({
                        formErrors:{apiErr:data.responseMessage},
                    },()=>{
                        setTimeout(() =>{
                            this.setState({formErrors:{}});
                        },Constants.WRNG_MSG_TIMEOUT);
                    });
                }
            });
        }
    }

    render() {
        return(
            <Modal style={{opacity:1}} className="custom-dialog custom-dialog-category" show={this.props.showCreateDialog} onHide={this.handleCreateDialogClose}>
                <Modal.Header>
                    <h5>{this.props.isEdit?"Edit User":"Add New New"}</h5>
                    <button 
                        onClick={this.handleCreateDialogClose}
                        type="button" data-dismiss="modal">
                        <span className="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    <div className="addnew-modal-form">
                        <div className="addnew-modal-form-group">
                            <p>User Name*</p>
                            <input type="text" placeholder="Enter Name" 
                            name="name" value={this.state.name} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['name']) &&
                                <span className="cm-error">{this.state.formErrors['name']}</span>
                            }
                        </div>
                        <div className="addnew-modal-form-group">
                            <p>Email*</p>
                            <input type="text" placeholder="Enter Email" 
                            name="email" value={this.state.email} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['email']) &&
                                <span className="cm-error">{this.state.formErrors['email']}</span>
                            }
                        </div>
                        {
                            !this.props.isEdit &&
                            <div className="addnew-modal-form-group">
                                <p>Password*</p>
                                <input type="text" placeholder="Enter Password" onKeyPress={this.onEnterBtnPress}
                                name="password" value={this.state.password} onChange={this.handleChange}/>
                                {
                                    !isEmptyVariable(this.state.formErrors['password']) &&
                                    <span className="cm-error">{this.state.formErrors['password']}</span>
                                }
                            </div>
                        }
                        {
                            !isEmptyVariable(this.state.formErrors['apiErr']) &&
                            <span className="cm-error">{this.state.formErrors['apiErr']}</span>
                        }
                    </div>
                    <div className="modal-close-save">
                        <div className="close-save-col">
                            <button onClick={this.handleCreateDialogClose} type="button" className="modal-close">Cancel</button>
                            <button className="modal-save" onClick={this.handleCreateCategory} type="button">Save</button>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default AddCategoryDialog;