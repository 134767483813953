import React, { Component } from 'react';
import * as Constants from '../../global/constants';
import Sidebar from '../../layoutsComponent/sidebar';
import axios from 'axios';
import Modal from 'react-bootstrap-modal';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import * as Api from '../../global/apiendpoints';
import { isEmptyArray, isEmptyVariable, getLocalStorageVariables, ifEmptyReturnEmptyStr } from "../../global/commonFunctions";

const userDetails = getLocalStorageVariables();

class Uploadtemplate extends Component {
    constructor(props) {
        super(props);

        let templateId = "";
        let isEdit = false;
        if(this.props.location.pathname.includes("edittemplate")){
            templateId = this.props.match.params.id;
            isEdit = true;
        }

        this.state = {
            templateId:templateId,
            isEdit:isEdit,

            templateName:"",
            seoTitle:"",
            seoDescription:"",
            categories:[],
            categoryMasterId:'',
            s3BucketPath:'',
            categoryName:"",
            feature1:'',
            feature2:'',
            feature3:'',
            filesIncluded1:'',
            filesIncluded2:'',
            filesIncluded3:'',
            filesIncluded4:'',
            description:'',
            
            isFree:'',
            isNew:'N',
            isFeatured:'N',
            hasFreeSample:'N',
            discountedPrice:'',
            originalPrice:'',
            documentation:'',
            compatibleBrowsers:'',
            livePreviewLink:'',
            status:'',
            errors:{},

            featuredImagePath:'',
            previewImage:'',
            attachmentImage1:'',
            attachmentImage2:'',
            attachmentImage3:'',
            attachmentImage4:'',
            showImageDialogModal:false,
            showImageMsg:'',

            showDeleteModal:false,
            deleteImageType:"",

            templateZip:"",
            freeSampleTemplateZip:"",
            showDeleteZipModal:false,

            isUploadingZip:false,
            isUploadingFreeSampleZip:false,
            isSubmitted:false,

        };
    }

    componentDidMount() {
        if(this.state.isEdit){
            this.getInitDataEdit();
        }else{
            this.getInitData();
        }
    }
    /****************UTIL FUNCTIONS**************/
    isAmount(val){
        let reg = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;
        if( !reg.test( val ) )
        {
            return false;
        }
        return true;
    }

    fileValidation(id){
        let fileInput = document.getElementById(id);
        let filePath = fileInput.value;
        let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

        if(!allowedExtensions.exec(filePath)){
            fileInput.value = '';
            return false;
        }else{
            return true;
        }
    }

    fileValidationzip(id){
        let fileInput = document.getElementById(id);
        let filePath = fileInput.value;
        let allowedExtensions = /(\.zip)$/i;
        if(!allowedExtensions.exec(filePath)){
            fileInput.value = '';
            return false;
        }else{
                return true;
            }
    }
    /****************UTIL FUNCTIONS**************/
    closeImageModal = ()=>{
        this.setState({
            showImageDialogModal:false,
            showImageMsg:''
        },()=>{
        });
    }

    closeDeleteModal = ()=>{
        this.setState({
            showDeleteModal:false,
            deleteImageType:''
        },()=>{
        });
    }

    handleChange = (e) => {
        if(e.target.name === "categoryMasterId"){
            if(!isEmptyArray(this.state.categories)){
                this.state.categories.map((item) => {
                    if(item.categoryMasterId == e.target.value)
                    {
                        this.setState({ 
                            categoryMasterId:e.target.value,
                            s3BucketPath:item.s3BucketPath,
                            categoryName:item.categoryName,
                        });
                    }
                });
            }
        }else{
            const { name, value } = e.target;
            this.setState({ [name]: value });
        }
    }
    
    validateForm()
    {
        let error_flag = false;
        let errors = {};
        if(isEmptyVariable(this.state.categoryMasterId))
        {
            error_flag = true;
            errors['categoryMasterId'] = "Please select category!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        if(isEmptyVariable(this.state.templateName))
        {
            error_flag = true;
            errors['templateName'] = "Please enter template name!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        if(isEmptyVariable(this.state.seoTitle))
        {
            error_flag = true;
            errors['seoTitle'] = "Please enter seo title";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        if(isEmptyVariable(this.state.seoDescription))
        {
            error_flag = true;
            errors['seoDescription'] = "Please enter seo description";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        if(isEmptyVariable(this.state.description))
        {
            error_flag = true;
            errors['description'] = "Please enter template description!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
       
        // TODO - Files Included Validation
        if(isEmptyVariable(this.state.filesIncluded1) &&
        isEmptyVariable(this.state.filesIncluded2) &&
        isEmptyVariable(this.state.filesIncluded3) &&
        isEmptyVariable(this.state.filesIncluded4)
        )
        {
            error_flag = true;
            errors['filesIncluded'] = "Please enter atleast one files included!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        if(isEmptyVariable(this.state.feature1))
        {
            error_flag = true;
            errors['feature1'] = "Please enter feature1!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        if(isEmptyVariable(this.state.feature2))
        {
            error_flag = true;
            errors['feature2'] = "Please enter feature2!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        // if(this.state.feature3))
        // {
        //     error_flag = true;
        //     errors['feature3'] = "Please enter feature3!";
        // }
        if(isEmptyVariable(this.state.isFree))
        {
            error_flag = true;
            errors['isFree'] = "Please select either free or not!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        if(!isEmptyVariable(this.state.isFree))
        {
            if(this.state.isFree === "N")
            {
                if(isEmptyVariable(this.state.discountedPrice))
                {
                    error_flag = true;
                    errors['discountedPrice'] = "Please enter discounted price!";
                    setTimeout(function(){
                        this.setState({errors:{}});
                    }.bind(this),Constants.WRNG_MSG_TIMEOUT);
                }
                if(!isEmptyVariable(this.state.discountedPrice ))
                {
                    if(!this.isAmount(this.state.discountedPrice))
                    {
                        error_flag = true;
                        errors['discountedPrice'] = "Please enter valid discounted price!";
                        setTimeout(function(){
                            this.setState({errors:{}});
                        }.bind(this),Constants.WRNG_MSG_TIMEOUT);
                    }
                }
                if(isEmptyVariable(this.state.originalPrice))
                {
                    error_flag = true;
                    errors['originalPrice'] = "Please enter original price!";
                    setTimeout(function(){
                        this.setState({errors:{}});
                    }.bind(this),Constants.WRNG_MSG_TIMEOUT);
                }
                if(!isEmptyVariable(this.state.originalPrice))
                {
                    if(!this.isAmount(this.state.originalPrice))
                    {
                        error_flag = true;
                        errors['originalPrice'] = "Please enter valid original price!";
                        setTimeout(function(){
                            this.setState({errors:{}});
                        }.bind(this),Constants.WRNG_MSG_TIMEOUT);
                    }
                }
            }
        }
        if(isEmptyVariable(this.state.documentation))
        {
            error_flag = true;
            errors['documentation'] = "Please select documentation!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        if(isEmptyVariable(this.state.compatibleBrowsers))
        {
            error_flag = true;
            errors['compatibleBrowsers'] = "Please enter compatible browsers!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        if(isEmptyVariable(this.state.livePreviewLink))
        {
            error_flag = true;
            errors['livePreviewLink'] = "Please enter live preview link!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        if(isEmptyVariable(this.state.status))
        {
            error_flag = true;
            errors['status'] = "Please select status!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }

        if(this.state.isFeatured === "Y")
        {
            if(isEmptyVariable(this.state.featuredImagePath))
            {
                error_flag = true;
                errors['featuredImagePath'] = "Please upload a featured image!";
                setTimeout(function(){
                    this.setState({errors:{}});
                }.bind(this),Constants.WRNG_MSG_TIMEOUT);
            }
        }

        if(isEmptyVariable(this.state.previewImage))
        {
            error_flag = true;
            errors['previewImage'] = "Please upload a preview image!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        if(isEmptyVariable(this.state.templateZip))
        {
            error_flag = true;
            errors['templateZip'] = "Please upload a template zip!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        
        if(this.state.hasFreeSample === "Y" &&
        isEmptyVariable(this.state.freeSampleTemplateZip))
        {
            error_flag = true;
            errors['freeSampleTemplateZip'] = "Please upload a sample template zip!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
       
        this.setState({
            errors: errors
        });
        return error_flag;
    }

    closeDeleteZipModal = ()=>{
        this.setState({
            showDeleteZipModal:false
        },()=>{
        });
    }

    cancelForm = () =>{
        window.location.reload();
    }
    
    uploadImage = (id,event) =>{
        let sizeInMB    =  (event.target.files[0].size / (1024*1024)).toFixed(2);
        if(!this.fileValidation(id)){
            this.setState({
                showImageDialogModal:true,
                showImageMsg:'Please upload file having extensions jpeg,jpg and png only.'
            });
        }
        else if(sizeInMB > Constants.VALID_TEMPLATE_IMAGE_SIZE){
            this.setState({
                showImageDialogModal:true,
                showImageMsg:'Please upload template image size less than '+Constants.VALID_TEMPLATE_IMAGE_SIZE+' MB'
            });
        }else{
            const formData = new FormData();
            formData.append('file',event.target.files[0]);
            formData.append('email',userDetails.email);
            formData.append('accessToken',userDetails.accessToken);

            let url = Api.UPLOAD_TEMP_IMAGE;
            axios.post(url,formData, {
                mode: 'cors',
            }).then(res => {
                if(res.data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    res.data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(res.data.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState({
                        [id]: res.data.fileName
                    });
                }else{
                    //TODO Error handling
                }
            }).catch(error => {
                //TODO Error handling
            });
        }
    }

    uploadTemplateZip = (isFreeSample,event) =>{
        if(!this.fileValidationzip(isFreeSample === "Y"?"freeSampleTemplateZip":"templateZip")){
            this.setState({
                showImageDialogModal:true,
                showImageMsg:'Please upload file having extensions zip only.'
            });
        }else{
            if(isFreeSample === "Y"){
                this.setState({
                    isUploadingFreeSampleZip:true,
                });
            }else{
                this.setState({
                    isUploadingZip:true,
                });
            }
            const formData = new FormData();
            formData.append('file',event.target.files[0]);
            formData.append('email',userDetails.email);
            formData.append('accessToken',userDetails.accessToken);

            let url = Api.UPLOAD_TEMP_IMAGE;
            axios.post(url,formData, {
                mode: 'cors',
            }).then(res => {
                if(res.data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    res.data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(res.data.responseCode === Constants.RESPONSE_CODE_SUCCESS){

                    if(isFreeSample === "Y"){
                        this.setState({
                            freeSampleTemplateZip: res.data.fileName,
                            isUploadingFreeSampleZip:false,
                        });
                    }else{
                        this.setState({
                            templateZip: res.data.fileName,
                            isUploadingZip:false,
                        });
                    }
                    
                }else{
                    //TODO Error handling
                    if(isFreeSample === "Y"){
                        this.setState({
                            isUploadingFreeSampleZip:true,
                        });
                    }else{
                        this.setState({
                            isUploadingZip:true,
                        });
                    }
                }
                
            }).catch(error => {
                //TODO Error handling
                if(isFreeSample === "Y"){
                    this.setState({
                        isUploadingFreeSampleZip:true,
                    });
                }else{
                    this.setState({
                        isUploadingZip:true,
                    });
                }
            });
        }
    }

    deleteImageConfirm = (image) =>{
        this.setState({
            showDeleteModal:true,
            deleteImageType:image
        });
    }

    deleteImage = () => {
        let image = this.state.deleteImageType;
        this.setState({
            [image]:"",
            showDeleteModal:false,
            deleteImageType:''
        });
        const file = document.querySelector('#'+image);
        file.value = '';
    }

    deleteZipConfirm = (isFreeSample) =>{
        this.setState({
            showDeleteZipModal:true,
            operation:isFreeSample === "Y"?"deleteFreeZip":"deleteZip"
        });
    }

    deleteZip = () => {
        this.setState({
            templateZip:"",
            operation:"",
            showDeleteZipModal:false
        });
        const file = document.querySelector('#templateZip');
        file.value = '';
    }

    deleteFreeSampleZip = () => {
        this.setState({
            freeSampleTemplateZip:"",
            operation:"",
            showDeleteZipModal:false
        });
        const file = document.querySelector('#freeSampleTemplateZip');
        file.value = '';
    }

    getInitData = () => {

        Promise.all([fetch(Api.GET_CATEGORIES,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(
                { 
                    email:userDetails.email,
                    accessToken:userDetails.accessToken
                })
            })
        ])
        .then(([res1]) => { 
            return Promise.all([res1.json()]) 
        })
        .then(([res1]) => {
            if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                localStorage.clear();
                window.location = "/";
            }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                this.setState({
                    categories:res1.data.result
                });
            }
        });
    }

    getInitDataEdit = () => {
        Promise.all([
            fetch(Api.GET_TEMPLATES_ADMIN,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(
                    { 
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        templateId: this.state.templateId,
                    })
                }),
                fetch(Api.GET_CATEGORIES,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams(
                    {
                        email:userDetails.email,
                        accessToken:userDetails.accessToken
                    })
                })
            ])
            .then(([res1,res2]) => { 
                return Promise.all([res1.json(),res2.json()]) 
            })
            .then(([res1,res2]) => {
                let templateObj = {};
                let filesIncluded1 = "";
                let filesIncluded2 = "";
                let filesIncluded3 = "";
                let filesIncluded4 = "";

                if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    templateObj = isEmptyArray(res1.data.result)?{}:res1.data.result[0];

                    let filesIncluded = templateObj.filesIncluded;
                    let filesIncludedArray = filesIncluded.split(",");
                    if(!isEmptyVariable(filesIncludedArray[0])){
                        filesIncluded1 = filesIncludedArray[0];
                    }
                    if(!isEmptyVariable(filesIncludedArray[1])){
                        filesIncluded2 = filesIncludedArray[1];
                    }
                    if(!isEmptyVariable(filesIncludedArray[2])){
                        filesIncluded3 = filesIncludedArray[2];
                    }
                    if(!isEmptyVariable(filesIncludedArray[3])){
                        filesIncluded3 = filesIncludedArray[3];
                    }
                }

                //now lets get the template attachments
                let temp = {};
                let counter = 1;
                if(!isEmptyArray(res1.data.templateImages)){
                    res1.data.templateImages.forEach((item)=>{
                        if(item.isPreview === "N"){
                            temp["attachmentImage"+counter] = item.imagePath;
                            temp["attachmentImage"+counter+"Old"] = item.imagePath;
                            counter++;
                        }else{
                            temp["previewImage"] = item.imagePath;
                            temp["previewImageOld"] = item.imagePath;
                        }
                    })
                }                

                this.setState({
                    categories:res2.data.result,

                    categoryMasterId:templateObj.categoryMasterId,
                    s3BucketPath:templateObj.s3BucketPath,
                    categoryName:templateObj.categoryName,

                    templateName:templateObj.templateName,
                    description:templateObj.description,

                    seoTitle:templateObj.seoTitle,
                    seoDescription:templateObj.seoDescription,

                    filesIncluded1:filesIncluded1,
                    filesIncluded2:filesIncluded2,
                    filesIncluded3:filesIncluded3,
                    filesIncluded4:filesIncluded4,

                    feature1:templateObj.feature1,
                    feature2:templateObj.feature2,
                    feature3:isEmptyVariable(templateObj.feature3)?"":templateObj.feature3,
                    isFree:templateObj.isFree,
                    isNew:templateObj.isNew,
                    isFeatured:templateObj.isFeatured,
                    hasFreeSample:templateObj.hasFreeSample,
                    discountedPrice:(templateObj.isFree === "N" && !isEmptyVariable(templateObj.discountedPrice))?templateObj.discountedPrice:"",
                    originalPrice:(templateObj.isFree === "N" && !isEmptyVariable(templateObj.originalPrice))?templateObj.originalPrice:"",
                    documentation:templateObj.documentation,
                    compatibleBrowsers:templateObj.compatibleBrowsers,
                    livePreviewLink:templateObj.livePreviewLink,
                    status:templateObj.templateStatus,

                    featuredImagePath:(templateObj.isFeatured === "Y")?templateObj.featuredImagePath:"",
                    featuredImagePathOld:(templateObj.isFeatured === "Y")?templateObj.featuredImagePath:"",

                    templateZip:templateObj.path,
                    templateZipOld:templateObj.path,

                    freeSampleTemplateZip:ifEmptyReturnEmptyStr(templateObj.freeSamplePath,""),
                    freeSampleTemplateZipOld:ifEmptyReturnEmptyStr(templateObj.freeSamplePath,""),

                    ...temp
                })

            }
        );
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(!this.validateForm()){
            this.setState({
                isSubmitted:true,
            });

            let filesIncluded = [];
            if(!isEmptyVariable(this.state.filesIncluded1))
            {
                filesIncluded.push(this.state.filesIncluded1);
            }
            if(!isEmptyVariable(this.state.filesIncluded2))
            {
                filesIncluded.push(this.state.filesIncluded2);
            }
            if(!isEmptyVariable(this.state.filesIncluded3))
            {
                filesIncluded.push(this.state.filesIncluded3);
            }
            if(!isEmptyVariable(this.state.filesIncluded4))
            {
                filesIncluded.push(this.state.filesIncluded4);
            }

            let postParams = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,

                templateName:this.state.templateName,
                description:this.state.description,
                seoTitle:this.state.seoTitle,
                seoDescription:this.state.seoDescription,
                filesIncluded:filesIncluded.join(','),
                feature1:this.state.feature1,
                feature2:this.state.feature2,
                feature3:this.state.feature3,
                isFree:this.state.isFree,
                isFeatured:this.state.isFeatured,
                hasFreeSample:this.state.hasFreeSample,
                isNew:this.state.isNew,
                discountedPrice:this.state.discountedPrice,
                originalPrice:this.state.originalPrice,
                categoryMasterId:this.state.categoryMasterId,
                s3BucketPath:this.state.s3BucketPath,
                documentation:this.state.documentation,
                compatibleBrowsers:this.state.compatibleBrowsers,
                livePreviewLink:this.state.livePreviewLink,
                status:this.state.status,
                
                tmpFeaturedImagePath:this.state.featuredImagePath,
                tmpPreviewImagePath:this.state.previewImage,
                templateZip:this.state.templateZip,
                freeSampleTemplateZip:this.state.freeSampleTemplateZip,
            }

            let url = Api.UPLOAD_TEMPLATE;
            let tmpAttachmentImagePathArray = [];

            if(this.state.isEdit){
                url = Api.EDIT_TEMPLATE;
                //lets add featured image old and preview image old
                postParams.templateId = this.state.templateId;
                postParams.featuredImagePathOld = this.state.featuredImagePathOld;
                postParams.previewImageOld = this.state.previewImageOld;
                postParams.templateZipOld = this.state.templateZipOld;
                postParams.freeSampleTemplateZipOld = this.state.freeSampleTemplateZipOld;

                for(let i=1;i<13;i++){
                    if(isEmptyVariable(this.state["attachmentImage"+i]) &&
                    isEmptyVariable(this.state["attachmentImage"+i+"Old"])){
                        //if both are empty then do nothing
                    }else{
                        let temp = {};
                        temp["attachmentImage"+i] = this.state["attachmentImage"+i];
                        temp["attachmentImage"+i+"Old"] = this.state["attachmentImage"+i+"Old"];

                        tmpAttachmentImagePathArray.push(temp);
                    }
                }

            }else{
                [1,2,3,4,5,6,7,8,9,10,11,12].forEach(attachmentNo=>{
                    if(!isEmptyVariable(this.state["attachmentImage"+attachmentNo]))
                    {
                        tmpAttachmentImagePathArray.push(this.state["attachmentImage"+attachmentNo]);
                    }
                })
            }

            postParams.tmpAttachmentImagePathArray = JSON.stringify(tmpAttachmentImagePathArray);

            fetch(url,
            {
                method: "POST",
                mode: 'cors',
                body: new URLSearchParams(postParams)
            })
            .then(response => { return response.json(); })
            .then(res1 => {
                if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    // let categoryurl = Constants.DEFAULT_CATEGORY_URL+(this.state.categoryName.replace(/\s-\s/g, ' ').replace(/\s+/g, '-')).toLowerCase();
                    let categoryurl = Constants.DEFAULT_CATEGORY_URL + this.state.categoryMasterId;
                    this.props.history.push(categoryurl);
                }else{
                    //TODO ERROR HANDLING
                    this.setState({
                        isSubmitted:false,
                        showImageDialogModal:true,
                        showImageMsg:res1.responseMessage
                    })
                }
            }); 
        }else{

        }
    }

    render() {
        return (
            <Router>
                <Sidebar />
                <div className="main-panel">
                    <nav className="header navbar">
                        <div className="header-inner">
                            <div className="navbar-item navbar-spacer-right brand hidden-lg-up">
                            <a href="javascript:;" data-toggle="sidebar" className="toggle-offscreen">
                                <i className="material-icons">menu</i>
                            </a>
                            </div>
                            <a className="navbar-item navbar-spacer-right navbar-heading hidden-md-down" href="javascript:void(0);">
                            <span className="tophead-txt">Upload Template</span>
                            <span className="tophead-txt pull-right"></span>
                            </a>
                        </div>
                    </nav>
            
                    <div className="main-content">
                        {
                            (this.state.isUploadingZip || this.state.isSubmitted || this.state.isUploadingFreeSampleZip) &&
                            <div class="loading-layout">
                                <div>
                                    <h6>Please wait...</h6>
                                </div>
                            </div>
                        }
                        <div className="content-view">
                            <div className="card">
                                <div className="sec-t-container m-b-2"><h4 className="card-title">Upload Template</h4></div>
                                <div className="card-block">
                                    <div>
                                        <form action="javascript:void(0);" id="upload_form">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <fieldset className="form-group">
                                                        <label for="templateName">
                                                        Template Name<span className="mandatory">*</span>
                                                        </label>
                                                        <input type="text" className="form-control form-control-md" 
                                                        name="templateName" 
                                                        onChange={this.handleChange} value={this.state.templateName}/>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.templateName) && 
                                                            <span className="err_msg">{this.state.errors.templateName}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                                <div className="col-md-6">
                                                    <fieldset className="form-group">
                                                        <label for="categoryMasterId">
                                                            Category<span className="mandatory">*</span>
                                                        </label>
                                                        <select name="categoryMasterId" className="srh-fld" onChange={this.handleChange} 
                                                        value={this.state.categoryMasterId}>
                                                            <option value="">Select Category</option>
                                                            {
                                                                !isEmptyArray(this.state.categories) && 
                                                                this.state.categories.map((item)=>{
                                                                    return <option value={item.categoryMasterId}>{item.categoryName}</option>
                                                                })
                                                            }
                                                        </select>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.categoryMasterId) && 
                                                            <span className="err_msg">{this.state.errors.categoryMasterId}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <fieldset className="form-group">
                                                        <label for="features">
                                                        Features<span className="mandatory">*</span>
                                                        </label>
                                                        <input type="text" className="form-control form-control-md" 
                                                        name="feature1" 
                                                        onChange={this.handleChange} value={this.state.feature1}/>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.feature1) && 
                                                            <span className="err_msg">{this.state.errors.feature1}</span>
                                                        }
                                                    </fieldset>
                                                    <fieldset className="form-group">
                                                        <input type="text" className="form-control form-control-md" 
                                                        name="feature2" 
                                                        onChange={this.handleChange} value={this.state.feature2}/>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.feature2) && 
                                                            <span className="err_msg">{this.state.errors.feature2}</span>
                                                        }
                                                    </fieldset>
                                                    <fieldset className="form-group">  
                                                        <input type="text" className="form-control form-control-md" name="feature3" onChange={this.handleChange} value={this.state.feature3}/>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.feature3) && 
                                                            <span className="err_msg">{this.state.errors.feature3}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                                <div className="col-md-6">
                                                    <fieldset className="form-group">
                                                        <label for="seoTitle">
                                                        SEO Title<span className="mandatory">*</span>
                                                        </label>
                                                        <input type="text" className="form-control form-control-md" 
                                                        name="seoTitle" 
                                                        onChange={this.handleChange} value={this.state.seoTitle}/>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.seoTitle) && 
                                                            <span className="err_msg">{this.state.errors.seoTitle}</span>
                                                        }
                                                    </fieldset>
                                                    <fieldset className="form-group">
                                                        <label for="seoDescription">
                                                        Seo Description<span className="mandatory">*</span>
                                                        </label>
                                                        <textarea type="text" className="form-control form-control-md" 
                                                        name="seoDescription" style={{height:56}}
                                                        onChange={this.handleChange} value={this.state.seoDescription}/>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.seoDescription) && 
                                                            <span className="err_msg">{this.state.errors.seoDescription}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <fieldset className="form-group">
                                                        <label for="filesIncluded">
                                                        Files Included<span className="mandatory">*</span>
                                                        </label>
                                                        <input type="text" className="form-control form-control-md" 
                                                        name="filesIncluded1" 
                                                        onChange={this.handleChange} value={this.state.filesIncluded1}/>
                                                    </fieldset>
                                                </div>
                                                <div className="col-md-6">
                                                    <fieldset className="form-group">
                                                        <label>&nbsp;</label>
                                                        <input type="text" className="form-control form-control-md" 
                                                        name="filesIncluded2" 
                                                        onChange={this.handleChange} value={this.state.filesIncluded2}/>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <fieldset className="form-group">
                                                        <input type="text" className="form-control form-control-md" 
                                                        name="filesIncluded3" 
                                                        onChange={this.handleChange} value={this.state.filesIncluded3}/>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.filesIncluded) && 
                                                            <span className="err_msg">{this.state.errors.filesIncluded}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                                <div className="col-md-6">
                                                    <fieldset className="form-group">
                                                        <input type="text" className="form-control form-control-md" 
                                                        name="filesIncluded4" 
                                                        onChange={this.handleChange} value={this.state.filesIncluded4}/>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <fieldset className="form-group">
                                                        <label for="compatibleBrowsers">
                                                        Compatible Browsers<span className="mandatory">*</span>
                                                        </label>
                                                        <input type="text" className="form-control form-control-md" 
                                                        name="compatibleBrowsers" 
                                                        onChange={this.handleChange} value={this.state.compatibleBrowsers}/>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.compatibleBrowsers) && 
                                                            <span className="err_msg">{this.state.errors.compatibleBrowsers}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                                <div className="col-md-6">
                                                    <fieldset className="form-group">
                                                        <label for="livePreviewLink">
                                                        Live Preview Link<span className="mandatory">*</span>
                                                        </label>
                                                        <input type="text" className="form-control form-control-md" 
                                                        name="livePreviewLink" 
                                                        onChange={this.handleChange} value={this.state.livePreviewLink}/>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.livePreviewLink) && 
                                                            <span className="err_msg">{this.state.errors.livePreviewLink}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                        <fieldset className="form-group">
                                                            <label for="documentation">
                                                            Documentation<span className="mandatory">*</span>
                                                            </label>
                                                            <select name="documentation" className="srh-fld" 
                                                            onChange={this.handleChange} value={this.state.documentation}>
                                                                <option value="">--select--</option>
                                                                <option value={Constants.TEMPLATE_DOC_WELL_DOCUMENTED}>{Constants.TEMPLATE_DOC_WELL_DOCUMENTED}</option>
                                                                <option value={Constants.TEMPLATE_DOC_NO_DOCUMENTATION}>{Constants.TEMPLATE_DOC_NO_DOCUMENTATION}</option>
                                                            </select>
                                                            {   
                                                                !isEmptyVariable(this.state.errors.documentation) && 
                                                                <span className="err_msg">{this.state.errors.documentation}</span>
                                                            }
                                                        </fieldset>
                                                    </div>
                                                <div className="col-md-6">
                                                    <fieldset className="form-group">
                                                        <label for="status">
                                                        Status<span className="mandatory">*</span>
                                                        </label>
                                                        <select name="status" className="srh-fld" onChange={this.handleChange} value={this.state.status}>
                                                            <option value="">--select--</option>
                                                            <option value={Constants.TEMPLATE_STATUS_ACTIVE}>{Constants.TEMPLATE_STATUS_ACTIVE}</option>
                                                            <option value={Constants.TEMPLATE_STATUS_DRAFT}>{Constants.TEMPLATE_STATUS_DRAFT}</option>
                                                            <option value={Constants.TEMPLATE_STATUS_INACTIVE}>{Constants.TEMPLATE_STATUS_INACTIVE}</option>
                                                        </select>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.status) && 
                                                            <span className="err_msg">{this.state.errors.status}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <fieldset className="form-group flex-center-layout">
                                                        <label>
                                                            Is Free<span className="mandatory">*</span>
                                                        </label>
                                                        <div className="inline-flex-center-layout">
                                                            <input type="radio" className="sal-radio" name="isFree" onChange={this.handleChange} value="Y" checked={this.state.isFree === "Y"}/><span className="contact-radio-label">Yes</span>
                                                        </div>
                                                        <div className="inline-flex-center-layout">
                                                            <input type="radio" className="sal-radio" name="isFree" onChange={this.handleChange} value="N" checked={this.state.isFree === "N"}/><span className="contact-radio-label">No</span>
                                                        </div>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.isFree) && 
                                                            <span className="err_msg">{this.state.errors.isFree}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                                <div className="col-md-6">
                                                    <fieldset className="form-group  flex-center-layout">
                                                        <label for="isNew">
                                                        Is New
                                                        </label>
                                                        <div className="inline-flex-center-layout">
                                                        <input type="radio" className="sal-radio" name="isNew" onChange={this.handleChange} value="Y" checked={this.state.isNew === "Y"}/><span className="contact-radio-label">Yes</span>
                                                        </div>
                                                        <div className="inline-flex-center-layout">
                                                        <input type="radio" className="sal-radio" name="isNew" onChange={this.handleChange} value="N" checked={this.state.isNew === "N"}/><span className="contact-radio-label">No</span>
                                                        </div>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.isNew) && 
                                                            <span className="err_msg">{this.state.errors.isNew}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                            </div>
                                            { 
                                                this.state.isFree === "N" && 
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <fieldset className="form-group">
                                                            <label for="discountedPrice">
                                                                Discounted Price<span className="mandatory">*</span>
                                                            </label>
                                                            <input type="text" className="form-control form-control-md" name="discountedPrice" onChange={this.handleChange} value={this.state.discountedPrice}/>
                                                            {   
                                                                !isEmptyVariable(this.state.errors.discountedPrice) && 
                                                                <span className="err_msg">{this.state.errors.discountedPrice}</span>
                                                            }
                                                        </fieldset>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <fieldset className="form-group">
                                                            <label for="originalPrice">
                                                                Original Price<span className="mandatory">*</span>
                                                            </label>
                                                            <input type="text" className="form-control form-control-md" name="originalPrice" onChange={this.handleChange} value={this.state.originalPrice}/>
                                                            {   
                                                                !isEmptyVariable(this.state.errors.originalPrice) && 
                                                                <span className="err_msg">{this.state.errors.originalPrice}</span>
                                                            }
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            }
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <fieldset className="form-group flex-center-layout">
                                                        <label for="isFeatured">
                                                            Is Featured
                                                        </label>
                                                        <div className="inline-flex-center-layout">
                                                        <input type="radio" className="sal-radio" name="isFeatured" onChange={this.handleChange} value="Y" checked={this.state.isFeatured === "Y"}/><span className="contact-radio-label">Yes</span>
                                                        </div>
                                                        <div className="inline-flex-center-layout">
                                                        <input type="radio" className="sal-radio" name="isFeatured" onChange={this.handleChange} value="N" checked={this.state.isFeatured === "N"}/><span className="contact-radio-label">No</span>
                                                        </div>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.isFeatured) && 
                                                            <span className="err_msg">{this.state.errors.isFeatured}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                                <div className="col-md-6">
                                                    <fieldset className="form-group  flex-center-layout">
                                                        <label for="hasFreeSample">
                                                        Has Free Sample
                                                        </label>
                                                        <div className="inline-flex-center-layout">
                                                        <input type="radio" className="sal-radio" name="hasFreeSample" onChange={this.handleChange} value="Y" checked={this.state.hasFreeSample === "Y"}/><span className="contact-radio-label">Yes</span>
                                                        </div>
                                                        <div className="inline-flex-center-layout">
                                                        <input type="radio" className="sal-radio" name="hasFreeSample" onChange={this.handleChange} value="N" checked={this.state.hasFreeSample === "N"}/><span className="contact-radio-label">No</span>
                                                        </div>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.hasFreeSample) && 
                                                            <span className="err_msg">{this.state.errors.hasFreeSample}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                            </div>
                                            {
                                                this.state.isFeatured === "Y" &&
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <fieldset className="form-group">
                                                            <label for="isFeatured">
                                                            Featured Image (734 x 443)<span className="mandatory">*</span>
                                                            </label>
                                                            <input id="featuredImagePath" type="file" 
                                                            className="form-control form-control-md" 
                                                            name="featuredImagePath" onChange={this.uploadImage.bind(this,"featuredImagePath")}/>
                                                            {
                                                                !isEmptyVariable(this.state.featuredImagePath) && 
                                                                <div>
                                                                    <div class="form-image-layout">
                                                                        <img className="img-preview" src={Constants.BASE_URL_API+this.state.featuredImagePath} />
                                                                        <button onClick = {(e) => this.deleteImageConfirm("featuredImagePath")}>
                                                                        <i className="material-icons">delete</i>
                                                                        </button>
                                                                    </div>
                                                                    <span>{this.state.featuredImagePath.split("-_-").pop()}</span>
                                                                </div>
                                                            }
                                                            {   
                                                                !isEmptyVariable(this.state.errors.featuredImagePath) && 
                                                                <span className="err_msg">{this.state.errors.featuredImagePath}</span>
                                                            }
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            }
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <fieldset className="form-group">
                                                        <label for="previewImage">
                                                            Preview Image (1000 x 750)<span className="mandatory">*</span>
                                                        </label>
                                                        <input id="previewImage" type="file" className="form-control form-control-md" 
                                                        name="previewImage" onChange={this.uploadImage.bind(this,"previewImage")}/>
                                                        {
                                                            !isEmptyVariable(this.state.previewImage) && 
                                                            <div>
                                                                <div class="form-image-layout">
                                                                    <img className="img-preview" src={Constants.BASE_URL_API+this.state.previewImage} />
                                                                    <button onClick = {(e) => this.deleteImageConfirm("previewImage")}>
                                                                    <i className="material-icons">delete</i>
                                                                    </button>
                                                                </div>
                                                                <span>{this.state.previewImage.split("-_-").pop()}</span>
                                                            </div>
                                                        }
                                                        {   
                                                            !isEmptyVariable(this.state.errors.previewImage) && 
                                                            <span className="err_msg">{this.state.errors.previewImage}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className="row" style={{display:"flex",flexWrap:"wrap"}}>
                                                {
                                                    [1,2,3,4,5,6,7,8,9,10,11,12].map(attachmentNo=>{
                                                        return <div className="col-md-3">
                                                            <fieldset className="form-group">
                                                                <label for="attachmentImage">
                                                                    {
                                                                        attachmentNo === 1
                                                                        ?<>Attachment Images<span className="mandatory"></span></>
                                                                        :<>&nbsp;<span className="mandatory"></span></>
                                                                    }
                                                                    
                                                                </label>
                                                                <input id={"attachmentImage"+attachmentNo} type="file" 
                                                                className="form-control form-control-md" 
                                                                name={"attachmentImage"+attachmentNo} 
                                                                onChange={this.uploadImage.bind(this,"attachmentImage"+attachmentNo)}/>
                                                                {
                                                                    !isEmptyVariable(this.state["attachmentImage"+attachmentNo]) && 
                                                                    <div>
                                                                        <div class="form-image-layout">
                                                                            <img className="img-preview" src={Constants.BASE_URL_API+this.state["attachmentImage"+attachmentNo]} />
                                                                            <button onClick = {(e) => this.deleteImageConfirm("attachmentImage"+attachmentNo)}>
                                                                            <i className="material-icons">delete</i>
                                                                            </button>
                                                                        </div>
                                                                        <span>{this.state["attachmentImage"+attachmentNo].split("-_-").pop()}</span>
                                                                    </div>
                                                                }
                                                            </fieldset>
                                                        </div>
                                                        })
                                                }
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <fieldset className="form-group">
                                                        <label for="templateZip">
                                                        Template Zip<span className="mandatory">*</span>
                                                        </label>
                                                        <input id="templateZip" type="file" 
                                                        className="form-control form-control-md" 
                                                        name="templateZip" 
                                                        onChange={this.uploadTemplateZip.bind(this,"N")}/>
                                                        {
                                                            !isEmptyVariable(this.state.templateZip) && 
                                                            <div>
                                                                <div class="form-image-layout" style={{display:"inline-block"}}>
                                                                    <img className="img-preview" style={{height:100}}src="/assets/images/zip-image.png" />
                                                                    <button onClick = {this.deleteZipConfirm.bind(this,"N")}>
                                                                    <i className="material-icons">delete</i>
                                                                    </button>
                                                                </div>
                                                                <span>{this.state.templateZip.split("-_-").pop()}</span>
                                                            </div>
                                                        }
                                                        {   
                                                            !isEmptyVariable(this.state.errors.templateZip) && 
                                                            <span className="err_msg">{this.state.errors.templateZip}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                                {
                                                    this.state.hasFreeSample === "Y" &&
                                                    <div className="col-md-6">
                                                        <fieldset className="form-group">
                                                            <label for="freeSampleTemplateZip">
                                                            Free Sample Template Zip<span className="mandatory">*</span>
                                                            </label>
                                                            <input id="freeSampleTemplateZip" type="file" 
                                                            className="form-control form-control-md" 
                                                            name="freeSampleTemplateZip" 
                                                            onChange={this.uploadTemplateZip.bind(this,"Y")}/>
                                                            {
                                                                !isEmptyVariable(this.state.freeSampleTemplateZip) && 
                                                                <div>
                                                                    <div class="form-image-layout" style={{display:"inline-block"}}>
                                                                        <img className="img-preview" style={{height:100}}src="/assets/images/zip-image.png" />
                                                                        <button onClick = {this.deleteZipConfirm.bind(this,"Y")}>
                                                                        <i className="material-icons">delete</i>
                                                                        </button>
                                                                    </div>
                                                                    <span>{this.state.freeSampleTemplateZip.split("-_-").pop()}</span>
                                                                </div>
                                                            }
                                                            {   
                                                                !isEmptyVariable(this.state.errors.freeSampleTemplateZip) && 
                                                                <span className="err_msg">{this.state.errors.freeSampleTemplateZip}</span>
                                                            }
                                                        </fieldset>
                                                    </div>
                                                }
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <fieldset className="form-group">
                                                        <label for="description">
                                                        Description<span className="mandatory">*</span>
                                                        </label>
                                                        <textarea rows="8" className="form-control form-control-md" 
                                                        name="description" style={{height:400}}
                                                        onChange={this.handleChange} value={this.state.description}/>
                                                        {   
                                                            !isEmptyVariable(this.state.errors.description) && 
                                                            <span className="err_msg">{this.state.errors.description}</span>
                                                        }
                                                    </fieldset>
                                                </div>
                                            </div>

                                            <a className="btn btn-info btn-md" type="button" href="javascript:void(0)" onClick={this.cancelForm}>
                                            Cancel
                                            </a>
                                            <button className="btn btn-primary btn-md" type="button" 
                                            disabled={this.state.isUploadingZip || this.state.isSubmitted || this.state.isUploadingFreeSampleZip}
                                            id="contact_submit" onClick={this.handleSubmit}>
                                            Save
                                            </button>
                                            <span className="err_msg" id="resp-msg"></span>
                                        </form>
                                    </div>     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showImageDialogModal} onHide={this.closeImageModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Warning!!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="image-msg-div">
                            <div className="wr-msg">{this.state.showImageMsg}</div>
                            <div className="text-center">
                                <button className="btn btn-info btn-md" type="button" onClick={this.closeImageModal}>OK</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showDeleteModal} onHide={this.closeDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Warning!!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="image-msg-div">
                            <div className="wr-msg">Are you sure you want to delete this image?</div>
                            <div className="text-center">
                                <button className="btn btn-info btn-md" type="button" onClick={this.closeDeleteModal}>No</button>
                                <button className="btn btn-primary btn-md" type="button" id="modal_button" onClick={this.deleteImage}>Yes</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showDeleteZipModal} onHide={this.closeDeleteZipModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Warning!!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="image-msg-div">
                            <div className="wr-msg">Are you sure you want to delete this zip file?</div>
                            <div className="text-center">
                                <button className="btn btn-info btn-md" type="button" onClick={this.closeDeleteZipModal}>No</button>
                                <button className="btn btn-primary btn-md" type="button" id="modal_button" 
                                onClick={this.state.operation==="deleteZip"?this.deleteZip:this.deleteFreeSampleZip}>Yes</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Router>
        );
    }
}
export default Uploadtemplate;