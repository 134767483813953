import * as URLs from '../../urls';

export const BASE_URL_API = URLs.API_BASE_URL_PATH;

export const RESULT_SET_SIZE = 12;

export const RESULT_SET_SIZE_DASHBOARD = 20;
export const RESULT_SIZE = 20;

export const WRNG_MSG_TIMEOUT = 10000;

export const VALID_PROFILE_IMAGE_SIZE = 1; // IN MB

export const REACT_THEMES_MASTER_CATEGORY_ID = 1;

export const REACT_THEMES_MASTER_CATEGORY_NAME = "React Themes";

export const UI_DESIGN_MASTER_CATEGORY_ID = 2;

export const UI_DESIGN_MASTER_CATEGORY_NAME = "UI Design Templates";

export const TEMPLATE_STATUS_ACTIVE = 'ACTIVE';
export const TEMPLATE_STATUS_INACTIVE = 'INACTIVE';
export const TEMPLATE_STATUS_DRAFT = 'DRAFT';

export const USER_TYPE_ADMIN = 'ADMIN';

export const USER_TYPE_PANELADMIN = 'PANELADMIN';

export const USER_STATUS_ACTIVE = 'ACTIVE';
export const USER_STATUS_BLOCKED= 'BLOCKED';

export const VALID_TEMPLATE_IMAGE_SIZE = 25; // IN MB

export const TEMPLATE_DOC_WELL_DOCUMENTED = "WELL DOCUMENTED";

export const TEMPLATE_DOC_NO_DOCUMENTATION = "NO DOCUMENTATION";

export const USER_PLATFORM_EMAIL = "Email";
export const USER_PLATFORM_Google = "Google";
export const USER_PLATFORM_Facebook = "Facebook";

export const DEFAULT_CATEGORY_URL = "/category/";

export const WEBSITE_TEMPLATE_URL = "https://www.reactrepo.com/template/";

export const HIRE_STATUS_READ = "READ";
export const HIRE_STATUS_UNREAD = "UNREAD";

export const RECAPTCHA_SITE_KEY = "6LelMvohAAAAAIOzzrg5MJpjExYDOAAShJ2Gtgok";

export const RESPONSE_CODE_ACCESSTOKEN_INVALID = 2;
export const RESPONSE_CODE_CATEGORY_URL_VALID = 0;


export const RESPONSE_CODE_SUCCESS = 0;
export const RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4 = 19;
export const RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 = 20;