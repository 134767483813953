import React, { Component } from 'react';
import * as Constants from '../../global/constants';
import Sidebar from '../../layoutsComponent/sidebar';
import Pagination from '../../pagesComponent/uiComponents/pagination';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {
    BrowserRouter as Router
} from 'react-router-dom';
import * as Api from '../../global/apiendpoints';
import { isEmptyArray, isEmptyVariable, getLocalStorageVariables, getLocalDateFromUTC,
    getUTCDateTimeFromLocal } from "../../global/commonFunctions";

const userDetails = getLocalStorageVariables();

class Order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPageNo: 1,
            pageOfItems: [],
            totalCount:0,
            errors:{},

            searchFilterFromDate: '',
            searchFilterToDate: '',
            orderNo:'',
            email:'',
            paymentId:'',
            invoiceNo:''
        };
    }

    componentDidMount() {
        this.getInitData();
    }

    handleSearchFromDate = (e) => {
         this.setState({ searchFilterFromDate: e });
    }

    handleSearchToDate = (e) => {
        this.setState({ searchFilterToDate: e });
    }

    handleSearch = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    search() {
        var error_flag = false;
        let errors = {};
        if (

            isEmptyVariable(this.state.orderNo)  &&
            isEmptyVariable(this.state.email)  &&
            isEmptyVariable(this.state.paymentId)  &&
            isEmptyVariable(this.state.invoiceNo)  &&
            isEmptyVariable(this.state.searchFilterFromDate)  &&
            isEmptyVariable(this.state.searchFilterToDate)  
        )
        {
            error_flag = true;
            errors['search'] = "Please select atleast one filter!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),Constants.WRNG_MSG_TIMEOUT);  
        }
        if (error_flag) {
            this.setState({
                errors: errors
            });
            return error_flag;
        }
        else {
            this.setState({currentPageNo:1},()=>{
                this.getAdminOrdersList();
            });
        }
    }

    onEnterBtnPress = (e) => {
        let code = e.keyCode || e.which;
        if(code === 13){
            this.search();
        }
    }

    clearFilter() {
        this.setState({
            searchFilterFromDate: '',
            searchFilterToDate: '',
            orderNo:'',
            email:'',
            paymentId:'',
            invoiceNo:''
        },()=>{
            this.getAdminOrdersList();
        });
        
    }

    getInitData = () =>{
        Promise.all([fetch(Api.GET_ORDERS_ADMIN,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({ 
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        pageNo: this.state.currentPageNo,
                        resultsize:Constants.RESULT_SIZE
                    })
                })
            ])
            .then(([res1]) => { 
                return Promise.all([res1.json()]) 
            })
            .then(([res1]) => {
                if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState({
                        pageOfItems:res1.data.result,
                        totalCount:res1.data.count,
                    });
                }else{
                    // TODO error handling
                }
          });
    }

    getAdminOrdersList() {

        fetch(Api.GET_ORDERS_ADMIN,
            {
                method: "POST",
                mode: 'cors',
                body: new URLSearchParams(
                { 
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo: this.state.currentPageNo, 
                    resultsize:Constants.RESULT_SIZE,
                    orderNo:this.state.orderNo,
                    searchEmail:this.state.email,
                    paymentId:this.state.paymentId,
                    invoiceNo:this.state.invoiceNo,
                    searchFilterFromDate: getUTCDateTimeFromLocal(this.state.searchFilterFromDate,false),
                    searchFilterToDate: getUTCDateTimeFromLocal(this.state.searchFilterToDate,true)
                }) 
            })
            .then(response => { return response.json(); })
            .then(data => {
                if(data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(data.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState({
                        pageOfItems:data.data.result,
                        totalCount:data.data.count,
                    });
                }
            });
    };

    onChangePage = (page) => {
        // alert(page);
        // update state with new page of items
        if(page != this.state.currentPageNo){
            fetch(Api.GET_ORDERS_ADMIN,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(
                    {
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        pageNo:page,
                        resultsize:Constants.RESULT_SIZE,
                        orderNo:this.state.orderNo,
                        searchEmail:this.state.email,
                        paymentId:this.state.paymentId,
                        invoiceNo:this.state.invoiceNo,
                        searchFilterFromDate: getUTCDateTimeFromLocal(this.state.searchFilterFromDate,false),
                        searchFilterToDate: getUTCDateTimeFromLocal(this.state.searchFilterToDate,true)
                    })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(data.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState({
                        pageOfItems:data.data.result,
                        totalCount:data.data.count,
                        currentPageNo:page,
                    });
                }
            });
        }
    }

    render() {
        return (
            <Router>
                <Sidebar />
                <div className="main-panel">
                    <nav className="header navbar">
                        <div className="header-inner">
                            <div className="navbar-item navbar-spacer-right brand hidden-lg-up">
                                <a href="javascript:;" data-toggle="sidebar" className="toggle-offscreen">
                                    <i className="material-icons">menu</i>
                                </a>
                            </div>
                            <a className="navbar-item navbar-spacer-right navbar-heading hidden-md-down" href="javascript:void(0);">
                                <span className="tophead-txt">Orders &gt;&gt; View List</span>
                                <span className="tophead-txt pull-right"></span>
                            </a>
                        </div>
                    </nav>

                    <div className="main-content">
                        <div className="content-view">

                            <div className="card">
                                <div className="sec-t-container m-b-2"><h4 className="card-title">Search Filters</h4></div>
                                    <div className="card-block">

                                        <div className="row">
                                            <div className="col-lg-3">
                                                <b>Order</b>
                                                <fieldset className="form-group">
                                                    <input placeholder="Order" className="srh-fld" name="orderNo" onChange={this.handleSearch} value={this.state.orderNo} onKeyPress={this.onEnterBtnPress} />
                                                </fieldset>
                                            </div>
                                            <div className="col-lg-3">
                                                <b>Email</b>
                                                <fieldset className="form-group">
                                                    <input placeholder="Email" className="srh-fld" name="email" onChange={this.handleSearch} value={this.state.email} onKeyPress={this.onEnterBtnPress} />
                                                </fieldset>
                                            </div>
                                            <div className="col-lg-3">
                                                <b>Payment ID</b>
                                                <fieldset className="form-group">
                                                    <input placeholder="Payment ID" className="srh-fld" name="paymentId" onChange={this.handleSearch} value={this.state.paymentId} onKeyPress={this.onEnterBtnPress}/>
                                                </fieldset>
                                            </div>
                                            <div className="col-lg-3">
                                                <b>Invoice No</b>
                                                <fieldset className="form-group">
                                                    <input placeholder="Invoice No" className="srh-fld" name="invoiceNo" onChange={this.handleSearch} value={this.state.invoiceNo} onKeyPress={this.onEnterBtnPress}/>
                                                </fieldset>
                                            </div>
                                        </div>

                                    <div className ="row">
                                        <div className="col-lg-3">
                                            <b>From Date</b>
                                            <DatePicker
                                                name="searchFromDate"
                                                selected={this.state.searchFilterFromDate}
                                                onChange={this.handleSearchFromDate}
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <b>To Date</b>
                                            <DatePicker
                                                name="searchToDate"
                                                selected={this.state.searchFilterToDate}
                                                onChange={this.handleSearchToDate}
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                        <div className="col-lg-3" style={{float:'right'}}>
                                            <b>&nbsp;</b>
                                            <div className="srch-fltr-btns">
                                                <button type="button" className="btn btn-primary" onClick={this.search.bind(this)}><i className="fa fa-search" aria-hidden="true"></i> <span className="filterText">Search</span></button>
                                                <button type="button" className="btn btn-danger" onClick={this.clearFilter.bind(this)}><i className="fa fa-times-circle-o" aria-hidden="true"></i><span className="filterText">&nbsp;Clear</span></button>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !isEmptyVariable(this.state.errors.search) && <span id="error_msg" className="err_msg">{this.state.errors.search}</span>
                                    }
                                </div>
                            </div>


                            <div className="card">
                                <div className="sec-t-container m-b-2 addclnt-title"><h4 className="card-title">Orders List</h4>
                                </div>
                                <div className="card-block">
                                    <div id="clientlist-wait" className="loader-login">
                                        <img src="/assets/images/spinner.GIF" width="64" height="64" />
                                    </div>
                                    <div className="">
                                        <table className="table m-b-0">
                                            <thead>
                                                <tr>
                                                    <th width="2%">No</th>
                                                    <th>Order</th>
                                                    <th>User Details</th>
                                                    <th width="20%">Order Details</th>
                                                    <th>Amount</th>
                                                    <th>Payment Details</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    !isEmptyArray(this.state.pageOfItems) &&
                                                     this.state.pageOfItems.map(function (item, index) {
                                                        let paymentStatus = "Paid";
                                                        let spanClass = "orders-paid-span";
                                                        if(item.paymentStatus === "PAYMENT RECEIVED"){
                                                            paymentStatus = "Paid";
                                                            spanClass = "orders-paid-span";
                                                        }else if(item.paymentStatus === "REFUNDED"){
                                                            paymentStatus = "Refunded";
                                                            spanClass = "orders-refunded-span";
                                                        }else{
                                                            paymentStatus = "Pending";
                                                            spanClass = "orders-pending-span";
                                                        }
                                                        return <tr>
                                                            <td>{((this.state.currentPageNo - 1) * Constants.RESULT_SIZE) + (++index)}</td>
                                                            <td>
                                                                <a href={"/orderdetails/"+item.orderNo}>
                                                                    {item.orderNo}</a>
                                                                <br/>
                                                                <span className={spanClass}>
                                                                {paymentStatus}
                                                                </span>
                                                            </td>
                                                            <td>{item.userName}<br/>{item.userEmail}</td>
                                                            <td>{item.templateName}</td>
                                                            <td>
                                                                {"$"+item.totalAmount}<br/>
                                                                {item.paymentId}
                                                            </td>
                                                                <td>
                                                                <strong>Invoice No: </strong>{item.invoiceNo}
                                                                <br/>
                                                            <strong>Date: </strong>{getLocalDateFromUTC(item.purchaseDate)}
                                                            </td>
                                                        </tr>;
                                                    }, this)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {
                                 !isEmptyArray(this.state.pageOfItems) && (this.state.totalCount > Constants.RESULT_SIZE) && 
                                <Pagination 
                                    totalLength ={this.state.totalCount} 
                                    items={this.state.pageOfItems} 
                                    onChangePage={this.onChangePage} 
                                    currentPageNo = {this.state.currentPageNo} />
                            }
                        </div>
                    </div>
                </div>
            </Router>
        );
    }
}
export default Order;