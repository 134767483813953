import React, { Component } from 'react';
import * as Constants from '../../global/constants';
import Sidebar from '../../layoutsComponent/sidebar';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import * as Api from '../../global/apiendpoints';
import { isEmptyArray, isEmptyVariable, getLocalStorageVariables, 
    ifEmptyReturnEmptyStr } from "../../global/commonFunctions";

const userDetails = getLocalStorageVariables();
const axios = require('axios');

class Templatedetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templateId:this.props.match.params.id,
            templateData:{},
            templateImages:[],
        };
    }

    componentDidMount() {
        this.getInitData();
    }

    getInitData = () => {
        Promise.all([fetch(Api.GET_TEMPLATES_ADMIN,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(
                    { 
                        templateId: this.state.templateId,
                        email:userDetails.email,
                        accessToken:userDetails.accessToken
                    })
                })
            ]
        )
            .then(([res1]) => { 
                return Promise.all([res1.json()]) 
            })
            .then(([res1]) => {
                let templateData = "";
                let templateImages = [];

                if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    templateData = res1.data.result[0];
                    templateImages = res1.data.templateImages;

                    this.setState({
                        templateData:templateData,
                        templateImages:templateImages,
                    });
                }
        });

    }

    downloadZip(templateId, isFreeSample)
    {
        const params = new URLSearchParams();
        params.append('templateId', templateId );
        params.append('email', userDetails.email );  
        params.append('accessToken', userDetails.accessToken );

        if(isFreeSample === "Y"){
            params.append('isFreeSample', isFreeSample);  
        }

        axios({
            url:Api.DOWNLOAD_ZIP,
            method:'post',
            mode: 'cors',
            data:params,
        })
        .then(response => {
            if(response.data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                response.data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                localStorage.clear();
                window.location = "/";
            }else if(response.data.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                let path = response.data.data.path;
                if(response.data.data.result.includes(path))
                {
                    let fileName = path.split("/").pop();
                    const link = document.createElement('a');
                    link.href = response.data.data.result;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            }else{
                alert(response.data.responseMessage);
            }
        })
    }

    render() {
        return (
            <Router>
                <Sidebar />
                <div className="main-panel">
                    <nav className="header navbar">
                        <div className="header-inner">
                            <div className="navbar-item navbar-spacer-right brand hidden-lg-up">
                                <a href="javascript:;" data-toggle="sidebar" className="toggle-offscreen">
                                    <i className="material-icons">menu</i>
                                </a>
                            </div>
                            <a className="navbar-item navbar-spacer-right navbar-heading hidden-md-down" href="javascript:void(0);">
                                <span className="tophead-txt">Template Details</span>
                                <span className="tophead-txt pull-right"></span>
                            </a>
                        </div>
                    </nav>

                    {
                        !isEmptyVariable(this.state.templateData) && 
                        <div className="main-content">
                            <div className="content-view">
                                <div className="card">
                                    <div className="sec-t-container m-b-2">
                                        <h4 className="card-title">
                                        {
                                            !isEmptyVariable(this.state.templateData.templateName)?
                                            this.state.templateData.templateName:"N/A"
                                        }
                                        <span className="status-span m-l-10">{this.state.templateData.templateStatus}</span>
                                        </h4>
                                    </div>
                                    <div className="card-block">
                                       <table className="table m-b-0 border-less">
                                            <tbody>
                                            <tr>
                                                    <td width="16%">
                                                        Feature 1: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.feature1)?
                                                        this.state.templateData.feature1:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Feature 2: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.feature2)?
                                                        this.state.templateData.feature2:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Feature 3: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.feature3)?
                                                        this.state.templateData.feature3:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Total Sales: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.totalSales)?
                                                        this.state.templateData.totalSales:0
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Views: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.views)?
                                                        this.state.templateData.views:0
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Downloads: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.downloads)?
                                                        this.state.templateData.downloads:0
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Category: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.categoryName)?
                                                        this.state.templateData.categoryName:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Created Date: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.templateCreateDate)?
                                                        (new Date(this.state.templateData.templateCreateDate).getDate()) + "-" + (new Date(this.state.templateData.templateCreateDate).getMonth() + 1) + "-" + (new Date(this.state.templateData.templateCreateDate).getFullYear())
                                                        :
                                                        "N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Updated Date: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.templateUpdateDate)?
                                                        (new Date(this.state.templateData.templateUpdateDate).getDate()) + "-" + (new Date(this.state.templateData.templateUpdateDate).getMonth() + 1) + "-" + (new Date(this.state.templateData.templateUpdateDate).getFullYear())
                                                        :
                                                        "N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Tags: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.tags)?
                                                        this.state.templateData.tags:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Is New: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.isNew) && (this.state.templateData.isNew === "Y")?
                                                        "Yes":"No"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        SEO Title: 
                                                    </td>
                                                    <td>
                                                    {
                                                        ifEmptyReturnEmptyStr(this.state.templateData.seoTitle,"-")
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        SEO Description: 
                                                    </td>
                                                    <td>
                                                    {
                                                        ifEmptyReturnEmptyStr(this.state.templateData.seoDescription,"-")
                                                    }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="flex-center-layout">
                                            <a className="btn btn-primary add-new-btn" 
                                                href="javascript:void(0);"
                                                onClick={this.downloadZip.bind(this,this.state.templateData.templateId,"N")}>
                                                <i className="material-icons">get_app</i>
                                                <span className="filterText">Download Zip</span>
                                            </a>
                                            <a className="btn btn-primary add-new-btn" 
                                                href={"/edittemplate/"+this.state.templateData.templateId}>
                                                <i className="material-icons">edit</i>
                                                <span className="filterText">Edit Template</span>
                                            </a>
                                            {
                                                this.state.templateData.hasFreeSample === "Y" &&
                                                <a className="btn btn-primary add-new-btn" 
                                                    href="javascript:void(0);"
                                                    onClick={this.downloadZip.bind(this,this.state.templateData.templateId,"Y")}>
                                                    <i className="material-icons">get_app</i>
                                                    <span className="filterText">Download Sample Zip</span>
                                                </a>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="sec-t-container m-b-2 addclnt-title"><h4 className="card-title">Pricing and Other Details</h4>
                                    </div>
                                    <div className="card-block">
                                        <table className="table m-b-0 border-less">
                                            <tbody>
                                                <tr>
                                                    <td width="16%">
                                                        Is Free: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.isFree) && (this.state.templateData.isFree === "Y")?
                                                        "Yes":"No"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Original Price: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.originalPrice)?
                                                        "$"+this.state.templateData.originalPrice:0
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Discounted Price: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.discountedPrice)?
                                                        "$"+this.state.templateData.discountedPrice:0
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Percentage off: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.originalPrice) && 
                                                            !isEmptyVariable(this.state.templateData.discountedPrice)?
                                                        Math.round(((this.state.templateData.originalPrice - this.state.templateData.discountedPrice)/this.state.templateData.originalPrice)*100)+"%":"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Files Included 1: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.filesIncluded) &&
                                                            (this.state.templateData.filesIncluded.split(",")[0]
                                                        )?
                                                        this.state.templateData.filesIncluded.split(",")[0]:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Files Included 2: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.filesIncluded) &&
                                                            (this.state.templateData.filesIncluded.split(",")[1]
                                                        )?
                                                        this.state.templateData.filesIncluded.split(",")[1]:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Files Included 3: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.filesIncluded) &&
                                                            (this.state.templateData.filesIncluded.split(",")[2]
                                                        )?
                                                        this.state.templateData.filesIncluded.split(",")[2]:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Compatible Browsers: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.templateData.compatibleBrowsers)?
                                                        this.state.templateData.compatibleBrowsers:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Documentation: 
                                                    </td>
                                                    <td>
                                                    {
                                                        ifEmptyReturnEmptyStr(this.state.templateData.documentation,"-")
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Live Preview Link: 
                                                    </td>
                                                    <td>
                                                    {
                                                        ifEmptyReturnEmptyStr(this.state.templateData.livePreviewLink,"-")
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Has Free Sample: 
                                                    </td>
                                                    <td>
                                                    {
                                                        this.state.templateData.hasFreeSample === "Y"?"Yes":"No"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Theme Preview Image: 
                                                    </td>
                                                    <td>
                                                    {
                                                        this.state.templateData.imagePath !=="" &&
                                                        <img width="120" height="100" src={Constants.BASE_URL_API+this.state.templateData.imagePath} />
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Other Images: 
                                                    </td>
                                                    <td>
                                                        {
                                                            !isEmptyArray(this.state.templateImages) &&
                                                            this.state.templateImages.map(image=>{
                                                                return<>
                                                                {
                                                                    image.isPreview !== "Y" &&
                                                                    <img width="120" height="100" src={Constants.BASE_URL_API+image.imagePath} />
                                                                }
                                                                </>
                                                            })
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>               
                                </div>
                            
                                <div className="card">
                                    <div className="sec-t-container m-b-2">
                                        <h4 className="card-title">
                                            Description
                                        </h4>
                                    </div>
                                    <div className="card-block">
                                    {
                                        (this.state.templateData.description)?
                                        <span dangerouslySetInnerHTML={{__html: this.state.templateData.description}} />
                                        :"N/A"
                                    }
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                </div>
            </Router>
        );
    }
}
export default Templatedetails;