import React, { Component } from 'react';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import * as Constants from '../global/constants';
import * as Api from '../global/apiendpoints';
import Sidebar from '../layoutsComponent/sidebar';
import moment from 'moment';
import { isEmptyVariable,getLocalStorageVariables, isEmptyArray, getLocalDateFromUTC } from '../global/commonFunctions';
let userDetails = {};

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboard:"",
            usersCount:"",
            ordersCount:"",
            reactThemesCount:"",
            uiDesignsCount:"",
            totalSalesToday:"",
            totalSalesYesterday:"",
            totalSalesWeek:"",
            totalSalesMonth:"",
            orders:"",
            hires:"",
            
        };
    }
    
    componentDidMount()
    {
        // IT should be inside here. Don't change it. Only for this page.
        userDetails = getLocalStorageVariables();
        this.getInitData();
        
    }

    getInitData = () =>{
        let today = moment().startOf("day").utc().format("YYYY-MM-DD HH:mm:ss");
        let endOfToday = moment().endOf("day").utc().format("YYYY-MM-DD HH:mm:ss");

        let yest = moment().startOf("day").utc().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss");
        let endOfyest = moment().endOf("day").utc().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss");

        let last7Days = moment().endOf("day").utc().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss");
        let last30Days = moment().endOf("day").utc().subtract(30, "days").format("YYYY-MM-DD HH:mm:ss");

        fetch(Api.GET_DASHBOARD,
        {
            method: "POST",
            mode: 'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                today:today,
                endOfToday:endOfToday,
                yest:yest,
                endOfyest:endOfyest,

                last7Days:last7Days,
                last30Days:last30Days,
            })
        })
        .then(response => { return response.json(); })
        .then(data => {
            let usersCount = "";
            let ordersCount = "";
            let reactThemesCount = "";
            let uiDesignsCount = "";
            let totalSalesToday = "";
            let totalSalesYesterday = "";
            let totalSalesWeek = "";
            let totalSalesMonth = "";
            let orders = "";
            let hires = "";

            if(data.responseCode  === Constants.RESPONSE_CODE_ACCESSTOKEN_INVALID)
            {
                localStorage.clear();
                window.location = "/";
            }
            else
            {
                usersCount = 
                    (!isEmptyVariable(data.data.usersCount) && data.data.usersCount >=0 )
                    ? data.data.usersCount
                    : "-";

                ordersCount = 
                    (!isEmptyVariable(data.data.ordersCount) && 
                    data.data.ordersCount >=0 )
                    ? data.data.ordersCount
                    : "-";
                    
                reactThemesCount = 
                    (!isEmptyVariable(data.data.reactThemesCount) && 
                    data.data.reactThemesCount >=0 )
                    ? data.data.reactThemesCount
                    : "-";
                    
                uiDesignsCount = 
                    (!isEmptyVariable(data.data.uiDesignsCount) && 
                    data.data.uiDesignsCount >=0 )
                    ? data.data.uiDesignsCount
                    : "-";
                
                totalSalesToday = 
                    (!isEmptyVariable(data.data.totalSalesToday) && 
                    data.data.totalSalesToday >=0 )
                    ? data.data.totalSalesToday
                    : "-";
                    
                totalSalesYesterday = 
                    (!isEmptyVariable(data.data.totalSalesYesterday) && 
                    data.data.totalSalesYesterday >=0 )
                    ? data.data.totalSalesYesterday
                    : "-";
                    
                totalSalesWeek = 
                    (!isEmptyVariable(data.data.totalSalesWeek) && 
                    data.data.totalSalesWeek >=0 )
                    ? data.data.totalSalesWeek
                    : "-";
                    
                totalSalesMonth = 
                    (!isEmptyVariable(data.data.totalSalesMonth) && 
                    data.data.totalSalesMonth >=0 )
                    ? data.data.totalSalesMonth
                    : "-";

                orders= data.data.latestOrders;
                hires= data.data.hires;

                this.setState({ 
                    dashboard: data.data,
                    
                    usersCount:usersCount,
                    ordersCount:ordersCount,
                    reactThemesCount:reactThemesCount,
                    uiDesignsCount:uiDesignsCount,
                    totalSalesToday:totalSalesToday,
                    totalSalesYesterday:totalSalesYesterday,
                    totalSalesWeek:totalSalesWeek,
                    totalSalesMonth:totalSalesMonth,
                    orders:orders,
                    hires:hires
                });
            }
        });

    }

    render() {
        return (
            <Router>
                <Sidebar />
                <div className="main-panel">
                    <nav className="header navbar">
                        <div className="header-inner">
                            <div className="navbar-item navbar-spacer-right brand hidden-lg-up">
                                <a href="javascript:;" data-toggle="sidebar" className="toggle-offscreen">
                                    <i className="material-icons">menu</i>
                                </a>
                            </div>
                            <a className="navbar-item navbar-spacer-right navbar-heading hidden-md-down" href="javascript:void(0);">
                                <span className="tophead-txt">Dashboard</span>
                                <span className="tophead-txt pull-right"></span>
                            </a>
                        </div>
                    </nav>

                    <div className="main-content">
                        <div className="content-view">
                            <div className="row">
                                <div className="col-md-3">
                                    <a href="/appusers">
                                        <div className="card">
                                            <div className="card-block">
                                            No. of Users
                                            <h4> {this.state.usersCount} </h4>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-3">
                                    <a href="/orders">
                                        <div className="card">
                                            <div className="card-block">
                                            Total Orders
                                            <h4> {this.state.ordersCount} </h4>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-3">
                                    <a href="/category/react-themes">
                                        <div className="card">
                                            <div className="card-block">
                                            No of React Theme Templates
                                            <h4>
                                            {this.state.reactThemesCount}
                                            </h4>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-3">
                                    <a href="/category/ui-design-templates">
                                        <div className="card">
                                            <div className="card-block">
                                            No. of UI Design Templates
                                            <h4>{this.state.uiDesignsCount}</h4>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-block">
                                        Total Sales Today
                                        <h4>{this.state.totalSalesToday}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-block">
                                        Total Sales Yesterday
                                        <h4>{this.state.totalSalesYesterday}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-block">
                                        Total Sales in past Week
                                        <h4>{this.state.totalSalesWeek}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-block">
                                        Total Sales in past Month
                                        <h4>{this.state.totalSalesMonth}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="sec-t-container m-b-2 addclnt-title"><h4 className="card-title">Orders List</h4>
                                        </div>
                                        <div className="card-block">
                                            <div className="">
                                                <table className="table m-b-0">
                                                    <thead>
                                                        <tr>
                                                            <th width="2%">No</th>
                                                            <th>Order</th>
                                                            <th>User Details</th>
                                                            <th width="20%">Order Details</th>
                                                            <th>Amount</th>
                                                            <th>Payment Details</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            !isEmptyArray(this.state.orders) &&
                                                            this.state.orders.map((item, index) => {
                                                                let paymentStatus = "Paid";
                                                                let spanClass = "orders-paid-span";
                                                                if(item.paymentStatus === "PAYMENT RECEIVED"){
                                                                    paymentStatus = "Paid";
                                                                    spanClass = "orders-paid-span";
                                                                }else if(item.paymentStatus === "REFUNDED"){
                                                                    paymentStatus = "Refunded";
                                                                    spanClass = "orders-refunded-span";
                                                                }else{
                                                                    paymentStatus = "Pending";
                                                                    spanClass = "orders-pending-span";
                                                                }
                                                                return <tr>
                                                                    <td>{(++index)}</td>
                                                                    <td>
                                                                        <a href={"/orderdetails/"+item.orderNo}>
                                                                            {item.orderNo}</a>
                                                                        <br/>
                                                                        <span className={spanClass}>
                                                                        {paymentStatus}
                                                                        </span>
                                                                    </td>
                                                                    <td>{item.userName}<br/>{item.userEmail}</td>
                                                                    <td>{item.templateName}</td>
                                                                    <td>
                                                                        {"$"+item.totalAmount}<br/>
                                                                        {item.paymentId}
                                                                    </td>
                                                                        <td>
                                                                        <strong>Invoice No: </strong>{item.invoiceNo}
                                                                        <br/>
                                                                    <strong>Date: </strong>{getLocalDateFromUTC(item.purchaseDate)}
                                                                    </td>
                                                                </tr>;
                                                            }, this)
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="sec-t-container m-b-2 addclnt-title"><h4 className="card-title">Hire Enquiries List</h4>
                                    </div>
                                    <div className="card-block">
                                        <div className="">
                                            <table className="table m-b-0">
                                                <thead>
                                                    <tr>
                                                        <th>Sl No</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Developer For</th>
                                                        <th>Hire For</th>
                                                        <th>Date</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                {
                                                    !isEmptyArray(this.state.hires) &&
                                                    this.state.hires.map(function (item, index) {
                                                        return <tr>
                                                            <td>{(++index)}</td>
                                                            <td><a href={"/hiredetails/"+item.hireId}>{item.name}</a></td>
                                                            <td>{item.email}</td>
                                                            <td>{item.developerfor}</td>
                                                            <td>{item.hirefor}</td>
                                                            <td>{getLocalDateFromUTC(item.createDate)}</td>
                                                            <td>
                                                                <span className={item.status==="UNREAD"?"status-span":"free-span"}>
                                                                    {item.status}
                                                                </span>
                                                            </td>
                                                        </tr>;
                                                    }, this)
                                                }
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </Router>
        );
    }
}
export default Dashboard;
