import React, { Component } from 'react';
import * as Constants from '../../global/constants';
import Sidebar from '../../layoutsComponent/sidebar';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import * as Api from '../../global/apiendpoints';
import { isEmptyVariable, getLocalStorageVariables } from "../../global/commonFunctions";

const userDetails = getLocalStorageVariables();

class Hiredetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hireId:this.props.match.params.id,
            hireData:"",
        };
    }

    componentDidMount() {
        this.getInitData();
    }

    getInitData = () => {
        Promise.all([
            fetch(Api.GET_HIRES,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({ 
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    hireId: this.state.hireId,
                })
            })
        ]
    )
        .then(([res]) => { 
            return Promise.all([res.json()]) 
        })
        .then(([res1]) => {
            if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                localStorage.clear();
                window.location = "/";
            }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                this.setState({
                    hireData:res1.data.result[0],
                    totalCount:res1.data.count,
                });
            }
        });

    }

    render() {
        return (
            <Router>
                <Sidebar />
                <div className="main-panel">
                    <nav className="header navbar">
                        <div className="header-inner">
                            <div className="navbar-item navbar-spacer-right brand hidden-lg-up">
                                <a href="javascript:;" data-toggle="sidebar" className="toggle-offscreen">
                                    <i className="material-icons">menu</i>
                                </a>
                            </div>
                            <a className="navbar-item navbar-spacer-right navbar-heading hidden-md-down" href="javascript:void(0);">
                                <span className="tophead-txt">Hire Details</span>
                                <span className="tophead-txt pull-right"></span>
                            </a>
                        </div>
                    </nav>

                    {
                        !isEmptyVariable(this.state.hireData) && 
                        <div className="main-content">
                            <div className="content-view">
                                <div className="card">
                                    <div className="sec-t-container m-b-2"><h4 className="card-title">
                                    Hire Details
                                    </h4></div>
                                        <div className="card-block">
                                        <h6 className="m-t-0">
                                        {
                                            !isEmptyVariable(this.state.hireData.name)?
                                            this.state.hireData.name:"N/A"
                                        }
                                        </h6>
                                        <table className="table m-b-0 border-less">
                                            <tbody>
                                                <tr>
                                                    <td width="16%">
                                                        Email: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.hireData.email)?
                                                        this.state.hireData.email:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Message: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.hireData.message)?
                                                        this.state.hireData.message:"N/A"
                                                    } 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Developer For: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.hireData.developerfor)?
                                                        this.state.hireData.developerfor:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Hire For: 
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.hireData.hirefor)?
                                                        this.state.hireData.hirefor:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Router>
        );
    }
}
export default Hiredetails;