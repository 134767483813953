import moment from "moment";

// Get varialbles from local storage
export const getLocalStorageVariables = () => {
    let userId = typeof window !== 'undefined'?localStorage.getItem('loggedInDetailsReactRepoAdmin')?JSON.parse(localStorage.getItem('loggedInDetailsReactRepoAdmin')).loggedInUserId:'':'';
    let email = typeof window !== 'undefined'?localStorage.getItem('loggedInDetailsReactRepoAdmin')?JSON.parse(localStorage.getItem('loggedInDetailsReactRepoAdmin')).loggedInUserEmail:'':'';
    let accessToken = typeof window !== 'undefined'?localStorage.getItem('loggedInDetailsReactRepoAdmin')?JSON.parse(localStorage.getItem('loggedInDetailsReactRepoAdmin')).loggedInUserAccessToken:'':'';
    let fullname = typeof window !== 'undefined'?localStorage.getItem('loggedInDetailsReactRepoAdmin')?JSON.parse(localStorage.getItem('loggedInDetailsReactRepoAdmin')).loggedInUserName:'':'';
    let lastName = typeof window !== 'undefined'?localStorage.getItem('loggedInDetailsReactRepoAdmin')?JSON.parse(localStorage.getItem('loggedInDetailsReactRepoAdmin')).loggedInLastName:'':'';
    let phoneNo = typeof window !== 'undefined'?localStorage.getItem('loggedInDetailsReactRepoAdmin')?JSON.parse(localStorage.getItem('loggedInDetailsReactRepoAdmin')).loggedInPhoneNo:'':'';
    let profilePic = typeof window !== 'undefined'?localStorage.getItem('loggedInDetailsReactRepoAdmin')?JSON.parse(localStorage.getItem('loggedInDetailsReactRepoAdmin')).loggedInProPic:'':'';
    
    let userData = {
        userId:userId===undefined?"":userId,
        email:email===undefined?"":email,
        accessToken:accessToken===undefined?"":accessToken,
        fullname:fullname===undefined?"":fullname,
        lastName:lastName===undefined?"":lastName,
        phoneNo:phoneNo===undefined?"":phoneNo,
        profilePic:profilePic===undefined?"":profilePic,
    }
    return userData;
}

//Check Variable is empty
export const isEmptyVariable = (variableName) => {
    if(variableName === ''){
        return true;
    }
        
    if(variableName === undefined){
        return true;
    }

    if(variableName === null){
        return true;
    }

    if(variableName === "null"){
        return true;
    }
    
    return false;
}

//Check if array is empty
export const isEmptyArray = (arrayName) => {
    if(arrayName === ''){
        return true;
    }
        
    if(arrayName === undefined){
        return true;
    }

    if(arrayName === null){
        return true;
    }

    if(!Array.isArray(arrayName)){
        return true;
    }

    if(arrayName.length === 0){
        return true;
    }
    
    return false;
}

export const ifEmptyReturnEmptyStr = (variableName, EmptyStr) => {
    return isEmptyVariable(variableName)?EmptyStr:variableName;
}

export const ifEmptyReturn = (variableName) => {
    return isEmptyVariable(variableName)?"":variableName;
}

export const removeHtmlTags = (htmlStr) => {
    return htmlStr.replace(/(<([^>]+)>)/gi, "");
}

export const sortTable = (sortColumn, currentSortColumn, currentSortDir) => {

    let sortObj = {
        sortDirTemp:"",
        sortTemp:sortColumn
    };
    
    //if the clicked column is same as previously clicked column
    //then just change the sorting direction
    //if it is different then change the column param to new & reinitialise sortdir to asc 
    if(currentSortColumn === sortColumn){
        if(currentSortDir === ""){
            sortObj.sortDirTemp = "asc";
        }else if(currentSortDir === "asc"){
            sortObj.sortDirTemp = "desc";
        }else if(currentSortDir === "desc"){
            sortObj.sortDirTemp = "";
            sortObj.sortTemp="";
        }
    }else{
        sortObj.sortDirTemp = "asc";
    }

    return sortObj;
}

export const sortTableTwoStates = (sortColumn, currentSortColumn, currentSortDir) => {

    let sortObj = {
        sortDirTemp:"",
        sortTemp:sortColumn
    };

    //if the clicked column is same as previously clicked column
    //then just change the sorting direction
    //if it is different then change the column param to new & reinitialise sortdir to asc 
    if(currentSortColumn === sortColumn){
        if(currentSortDir === ""){
            sortObj.sortDirTemp = "asc";
        }else if(currentSortDir === "asc"){
            sortObj.sortDirTemp = "desc";
        }else if(currentSortDir === "desc"){
            //Go back to default setting
            sortObj.sortDirTemp = "asc";
        }
    }else{
        sortObj.sortDirTemp = "asc";
    }

    return sortObj;
}

export const validatePassword = (password) =>{
    let respObj = {
        err:false,
        responseMsg:""
    }

    // password empty
    if(isEmptyVariable(password)){
        respObj = {
            err:true,
            responseMsg:"Please enter password"
        }
        return respObj;
    }

    // password length should be greater than 8
    if(password.length < 7){
        respObj = {
            err:true,
            responseMsg:"Password must have minimum 8 characters"
        }
        return respObj;
    }

    return respObj;
}

export const truncateString = (str, num) => {
    
    if(isEmptyVariable(str)){
        return "-";
    }

    if(str.length <= num){
        return str;
    }

    if(str.length > num){
        return str.substring(0,num) + "...";
    }
}

export const truncateStringV2 = (str, num) => {
    
    if(isEmptyVariable(str)){
        return "";
    }

    if(str.length <= num){
        return str;
    }

    if(str.length > num){
        return str.substring(0,num) + "...";
    }
}

//PROJECT SPECIFIC FUNCTIONS
export const SplitJoinString = (str, splitParam1, splitParam2,joinParam,pos) => {
    // alert(str)
    let resArr = [];
    let position = pos;
    if(isEmptyVariable(pos)){
        position = 0;
    }
    if(isEmptyVariable(str)) return "";

    let splitArr = str.split(splitParam1);

    if(isEmptyArray(splitArr)) return "";

    splitArr.map((item)=>{
        resArr.push(item.split(splitParam2)[position])
    })

    return resArr.join(joinParam);
        
}
export const DoubleSplitString = (str, splitParam1, splitParam2,pos) => {
    // alert(str)
    let resArr = [];
    let position = pos;
    if(isEmptyVariable(pos)){
        position = 0;
    }
    if(isEmptyVariable(str)) return "";

    let splitArr = str.split(splitParam1);

    if(isEmptyArray(splitArr)) return "";

    splitArr.map((item)=>{
        resArr.push(item.split(splitParam2)[position])
    })

    return resArr;
        
}

export const getOnlyFileNameFromPath = (path) => {
    if(isEmptyVariable(path)){
        return "";
    }

    return path.split("_-_").pop();
}

export const getUTCDateTimeFromLocal = (dateStr,isEOD) => {
    // console.log(dateStr);
    if(isEmptyVariable(dateStr)){
        return "";
    }
    
    var localDate = moment(dateStr);
    if(isEOD){
        localDate = localDate.endOf("day");
    }
    return localDate.utc().format("YYYY-MM-DD HH:mm:ss");
}

export const getLocalDateFromUTC = (dateStr) => {
    if(isEmptyVariable(dateStr)){
        return "";
    }
    var utcDate = moment.utc(dateStr);
    return utcDate.local().format("DD-MMM-YYYY hh:mm a");
}

export const getLocalDateOnlyFromUTC = (dateStr) => {
    if(isEmptyVariable(dateStr)){
        return "";
    }
    var utcDate = moment.utc(dateStr);
    return utcDate.local().format("DD-MMM-YYYY");
}