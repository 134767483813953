import React, { Component } from 'react';
import * as Constants from '../../global/constants';
import Sidebar from '../../layoutsComponent/sidebar';
import Pagination from '../../pagesComponent/uiComponents/pagination';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import * as Api from '../../global/apiendpoints';
import { isEmptyVariable, getLocalStorageVariables, getLocalDateFromUTC, isEmptyArray } from "../../global/commonFunctions";

const userDetails = getLocalStorageVariables();

class Userdetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId:this.props.match.params.id,
            currentPageNo: 1,
            dataList: [],
            totalCount:0,
            userData:"",
        };
    }

    componentDidMount() {
        this.getInitData();
    }

    getInitData = () => {
        Promise.all([fetch(Api.GET_USERS,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(
                { 
                    userId: this.state.userId,
                    email:userDetails.email,
                    accessToken:userDetails.accessToken
                })
            }),
            fetch(Api.GET_ORDERS_ADMIN,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({ 
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    userId: this.state.userId,
                    pageNo: this.state.currentPageNo,
                })
            })
        ]
    )
        .then(([userResp,orderResp]) => { 
            return Promise.all([userResp.json(),orderResp.json()]) 
        })
        .then(([userResp,orderResp]) => {
            let userData= "";
            let dataList = "";
            let totalCount = "";

            if(userResp.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                userResp.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                localStorage.clear();
                window.location = "/";
            }else if(userResp.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                userData = userResp.data.result[0];
            }

            if(orderResp.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                orderResp.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                localStorage.clear();
                window.location = "/";
            }else if(orderResp.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                dataList = orderResp.data.result;
                totalCount = orderResp.data.count;
            }

            this.setState({
                userData:userData,
                dataList:dataList,
                totalCount:totalCount,
            });

      });

    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page != this.state.currentPageNo){
            fetch(Api.GET_ORDERS_ADMIN,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(
                    {
                        pageNo: page, 
                        userId: this.state.userId,
                        email:userDetails.email,
                        accessToken:userDetails.accessToken
                    })
            })
            .then(response => { return response.json(); } )
            .then(res1 =>
            {

                if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState({
                        dataList:res1.data.result,
                        totalCount:res1.data.count,
                        currentPageNo:page,
                    });
                }else{
                    // TODO error handling
                }
            });
        }
    }

    render() {
        return (
            <Router>
                <Sidebar />
                <div className="main-panel">
                    <nav className="header navbar">
                        <div className="header-inner">
                            <div className="navbar-item navbar-spacer-right brand hidden-lg-up">
                                <a href="javascript:;" data-toggle="sidebar" className="toggle-offscreen">
                                    <i className="material-icons">menu</i>
                                </a>
                            </div>
                            <a className="navbar-item navbar-spacer-right navbar-heading hidden-md-down" href="javascript:void(0);">
                                <span className="tophead-txt">User Details</span>
                                <span className="tophead-txt pull-right"></span>
                            </a>
                        </div>
                    </nav>

                    {
                        !isEmptyVariable(this.state.userData) && 
                        <div className="main-content">
                            <div className="content-view">
                                <div className="card">
                                    <div className="sec-t-container m-b-2">
                                        <h4 className="card-title">User Details</h4>
                                    </div>
                                    <div className="card-block">
                                        <table className="table m-b-0 border-less">
                                            <tbody>
                                                <tr>
                                                    <td width="16%">
                                                        Name
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.userData.name)?
                                                        this.state.userData.name:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Email
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.userData.email)?
                                                        this.state.userData.email:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Phone
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.userData.phoneNo)?
                                                        this.state.userData.phoneNo:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Platform
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.userData.platform)?
                                                        this.state.userData.platform:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Registration Date
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.userData.createDate)?
                                                        (new Date(this.state.userData.createDate).getDate()) + "-" + (new Date(this.state.userData.createDate).getMonth() + 1) + "-" + (new Date(this.state.userData.createDate).getFullYear())
                                                        :
                                                        "N/A"
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                        Orders
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.userData.orderCount)?
                                                        this.state.userData.orderCount:0
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="16%">
                                                    Status
                                                    </td>
                                                    <td>:</td>
                                                    <td>
                                                    {
                                                        !isEmptyVariable(this.state.userData.status)?
                                                        this.state.userData.status:"N/A"
                                                    }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {
                                    this.state.userData.userType === "ADMIN" &&
                                    <>
                                        <div className="card">
                                            <div className="sec-t-container m-b-2 addclnt-title"><h4 className="card-title">Orders List</h4>
                                            </div>
                                            <div className="card-block">
                                                <div className="">
                                                    <table className="table m-b-0">
                                                        <thead>
                                                            <tr>
                                                                <th width="2%">No</th>
                                                                <th>Order</th>
                                                                <th>User Details</th>
                                                                <th width="20%">Order Details</th>
                                                                <th>Amount</th>
                                                                <th>Payment Details</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                !isEmptyArray(this.state.dataList) &&
                                                                this.state.dataList.map(function (item, index) {
                                                                    let paymentStatus = "Paid";
                                                                    let spanClass = "orders-paid-span";
                                                                    if(item.paymentStatus === "PAYMENT RECEIVED"){
                                                                        paymentStatus = "Paid";
                                                                        spanClass = "orders-paid-span";
                                                                    }else if(item.paymentStatus === "REFUNDED"){
                                                                        paymentStatus = "Refunded";
                                                                        spanClass = "orders-refunded-span";
                                                                    }else{
                                                                        paymentStatus = "Pending";
                                                                        spanClass = "orders-pending-span";
                                                                    }
                                                                    return <tr>
                                                                        <td>{((this.state.currentPageNo - 1) * Constants.RESULT_SIZE) + (++index)}</td>
                                                                        <td>
                                                                            <a href={"/orderdetails/"+item.orderNo}>
                                                                                {item.orderNo}</a>
                                                                            <br/>
                                                                            <span className={spanClass}>
                                                                            {paymentStatus}
                                                                            </span>
                                                                        </td>
                                                                        <td>{item.userName}<br/>{item.userEmail}</td>
                                                                        <td>{item.templateName}</td>
                                                                        <td>
                                                                            {"$"+item.totalAmount}<br/>
                                                                            {item.paymentId}
                                                                        </td>
                                                                            <td>
                                                                            <strong>Invoice No: </strong>{item.invoiceNo}
                                                                            <br/>
                                                                        <strong>Date: </strong>{getLocalDateFromUTC(item.purchaseDate)}
                                                                        </td>
                                                                    </tr>;
                                                                }, this)
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (this.state.totalCount > Constants.RESULT_SIZE) && 
                                            <Pagination 
                                                totalLength ={this.state.totalCount} 
                                                items={this.state.dataList} 
                                                onChangePage={this.onChangePage} 
                                                currentPageNo = {this.state.currentPageNo} />
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    }
                </div>
            </Router>
        );
    }
}
export default Userdetails;