import React, { Component } from 'react';
import * as Constants from '../../global/constants';
import Sidebar from '../../layoutsComponent/sidebar';
import Pagination from '../uiComponents/pagination';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as Api from '../../global/apiendpoints';
import { isEmptyArray, isEmptyVariable, getLocalStorageVariables, 
    getLocalDateOnlyFromUTC, ifEmptyReturnEmptyStr } from "../../global/commonFunctions";
import AddEditCategoryDialog from "./addEditCategory";
import Modal from 'react-bootstrap-modal';

const userDetails = getLocalStorageVariables();

class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPageNo: 1,
            dataList: [],
            totalCount:0,
            errors:[],
            categoryName:'',
            searchFilterFromDate: '',
            searchFilterToDate: '',

            showCreateDialog:false,
            isEdit:false,
            editItem:"",

            showModal:false,
        };
    }

    componentDidMount() {
        this.getInitData();
    }

    handleSearch = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSearchFromDate = (e) => {
        this.setState({ searchFilterFromDate: e });
    }

    handleSearchToDate = (e) => {
        this.setState({ searchFilterToDate: e });
    }

    search() {
        var error_flag = false;
        let errors = {};
        if (
            isEmptyVariable(this.state.categoryName)  &&
            isEmptyVariable(this.state.searchFilterFromDate ) && 
            isEmptyVariable(this.state.searchFilterToDate ) 
        )
        {
            error_flag = true;
            errors['search'] = "Please select atleast one filter!";
            setTimeout(()=>{
                this.setState({errors:{}});
           },Constants.WRNG_MSG_TIMEOUT);  
        }
        if (error_flag) {
            this.setState({
                errors: errors
            });
            return error_flag;
        }
        else {
            this.setState({currentPageNo:1},()=>{
                this.getCategoriesList();
            });
        }
    }
    
    onEnterBtnPress = (e) => {
        let code = e.keyCode || e.which;
        if(code === 13){
            this.search();
        }
    }

    clearFilter = () => {
        this.setState({
            categoryName:'',
            searchFilterFromDate: '',
            searchFilterToDate: '',
        },()=>{
            this.getCategoriesList();
        });
        
    }

    handleCreateDialogClose = (reloadFlag) =>{
        this.setState({
            showCreateDialog:false,
        },()=>{
            if(reloadFlag){
                this.getCategoriesList();
            }
        });
    }
    
    handleShowCreateDialog = () => {
        this.setState({
            showCreateDialog:true,
            isEdit:false,
            editItem:""
        });
    }

    handleEditCategory = (item) =>{
        this.setState({
            showCreateDialog:true,
            isEdit:true,
            editItem:item
        });
    }

    handleShowDeleteCategory = (item) =>{
        this.setState({
            showModal:true,
            isEdit:true,
            editItem:item
        });
    }

    handleHideDeleteCategory = () =>{
        this.setState({
            showModal:false,
        });
    }

    getInitData = () => {
        Promise.all([
            fetch(Api.GET_CATEGORIES,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(
                    { 
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        pageNo: this.state.currentPageNo,
                        resultsize:Constants.RESULT_SIZE,
                })
            })
        ])
        .then(([res1]) => { 
            return Promise.all([res1.json()]) 
        })
        .then(([data]) => {
            if(data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                localStorage.clear();
                window.location = "/";
            }else if(data.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                this.setState({
                    dataList:data.data.result,
                    totalCount:data.data.count,
                });
            }
      });

    }

    getCategoriesList() {

        fetch(Api.GET_CATEGORIES,
            {
                method: "POST",
                mode: 'cors',
                body: new URLSearchParams(
                { 
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo: this.state.currentPageNo,
                    resultsize:Constants.RESULT_SIZE,
                    categoryNameLike: this.state.categoryName,
                    searchFilterFromDate: this.state.searchFilterFromDate,
                    searchFilterToDate: this.state.searchFilterToDate,
                }) 
            })
            .then(response => { return response.json(); })
            .then(data => {
                if(data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(data.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState({
                        dataList:data.data.result,
                        totalCount:data.data.count,
                    });
                }
            });
    };

    deleteCategory = () => {
        fetch(Api.DELETE_CATEGORY,
            {
                method: "POST",
                mode: 'cors',
                body: new URLSearchParams(
                { 
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    categoryMasterId:this.state.editItem.categoryMasterId
                }) 
            })
            .then(response => { return response.json(); })
            .then(data => {
                if(data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(data.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState({
                        showModal:false,
                    },()=>{
                        this.getCategoriesList();
                    });
                }else{
                    alert(data.responseMessage);
                    this.setState({
                        showModal:false,
                    })
                }
            });
    };

    onChangePage = (page) => {
        // alert(page);
        // update state with new page of items
        if(page != this.state.currentPageNo){
            fetch(Api.GET_CATEGORIES,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(
                    {
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        pageNo: page, 
                        categoryNameLike: this.state.categoryName,
                        searchFilterFromDate: this.state.searchFilterFromDate,
                        searchFilterToDate: this.state.searchFilterToDate,
                    })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    data.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(data.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState({
                        dataList:data.data.result,
                        totalCount:data.data.count,
                        currentPageNo:page,
                    });
                }
            });
        }
    }

    render() {
        return (
            <Router>
                <Sidebar />
                <div className="main-panel">
                    <nav className="header navbar">
                        <div className="header-inner">
                            <div className="navbar-item navbar-spacer-right brand hidden-lg-up">
                                <a href="javascript:;" data-toggle="sidebar" className="toggle-offscreen">
                                    <i className="material-icons">menu</i>
                                </a>
                            </div>
                            <a className="navbar-item navbar-spacer-right navbar-heading hidden-md-down" href="javascript:void(0);">
                                <span className="tophead-txt">Categories >> View List</span>
                                <span className="tophead-txt pull-right"></span>
                            </a>
                        </div>
                    </nav>

                    <div className="main-content">
                        <div className="content-view">

                            <div className="card">
                                <div className="sec-t-container m-b-2"><h4 className="card-title">Search Filters</h4></div>
                                <div className="card-block">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <b>Name</b>
                                            <fieldset className="form-group">
                                                <input placeholder="Category Name" className="srh-fld" 
                                                name="categoryName" onChange={this.handleSearch} 
                                                value={this.state.categoryName} onKeyPress={this.onEnterBtnPress}/>
                                            </fieldset>
                                        </div>
                                        <div className="col-lg-3">
                                            <b>From Date</b>
                                            <DatePicker
                                                name="searchFromDate"
                                                selected={this.state.searchFilterFromDate}
                                                onChange={this.handleSearchFromDate}
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <b>To Date</b>
                                            <DatePicker
                                                name="searchToDate"
                                                selected={this.state.searchFilterToDate}
                                                onChange={this.handleSearchToDate}
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                        <div className="col-lg-3" style={{float:'right'}}>
                                            <b>&nbsp;</b>
                                            <div className="srch-fltr-btns">
                                                <button type="button" className="btn btn-primary" onClick={this.search.bind(this)}><i className="fa fa-search" aria-hidden="true"></i> <span className="filterText">Search</span></button>
                                                <button type="button" className="btn btn-danger" onClick={this.clearFilter.bind(this)}><i className="fa fa-times-circle-o" aria-hidden="true"></i><span className="filterText">&nbsp;Clear</span></button>
                                            </div>
                                        </div>
                                    </div>
                                    <span id="error_msg" className="err_msg">{this.state.errors.search}</span>
                                </div>
                            </div>

                            <div className="card">
                                <div className="sec-t-container m-b-2 heading-add-btn"><h4 className="card-title">Categories List</h4>
                                    <a className="btn btn-primary add-new-btn" onClick={this.handleShowCreateDialog}>
                                        <i className="material-icons">control_point</i>
                                        <span className="filterText">Add Category</span>
                                    </a>
                                </div>
                                <div className="card-block">
                                    <div id="clientlist-wait" className="loader-login">
                                        <img src="/assets/images/spinner.GIF" width="64" height="64" />
                                    </div>
                                    <div className="">
                                        <table className="table m-b-0">
                                            <thead>
                                                <tr>
                                                    <th width={"5%"}>No</th>
                                                    <th>Category Name</th>
                                                    <th>S3 Bucket Path</th>
                                                    <th>Templates</th>
                                                    <th>Create Date</th>
                                                    <th>Last Update Date</th>
                                                    <th>Last Updated By</th>
                                                    <th width={"5%"}>Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    !isEmptyArray(this.state.dataList) && 
                                                    this.state.dataList.map(function (item, index) {
                                                        return <tr>
                                                            <td>{((this.state.currentPageNo - 1) * Constants.RESULT_SET_SIZE) + (++index)}</td>
                                                            <td>{item.categoryName}</td>
                                                            <td>{item.s3BucketPath}</td>
                                                            <td>{item.templateCount}</td>
                                                            <td>
                                                                {getLocalDateOnlyFromUTC(item.createDate)}
                                                            </td>
                                                            <td>
                                                                {getLocalDateOnlyFromUTC(item.lastUpdateDate)}
                                                            </td>
                                                            <td>
                                                                {ifEmptyReturnEmptyStr(item.lastUpdatedByUser,"-")}
                                                            </td>
                                                            <td>
                                                                <a onClick={this.handleEditCategory.bind(this,item)}>
                                                                    <i className="material-icons">edit</i>
                                                                </a>
                                                                <a onClick={this.handleShowDeleteCategory.bind(this,item)}>
                                                                    <i className="material-icons">delete</i>
                                                                </a>
                                                            </td>
                                                        </tr>;
                                                    }, this)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {
                                (this.state.totalCount > Constants.RESULT_SET_SIZE) && 
                                <Pagination 
                                    totalLength ={this.state.totalCount} 
                                    items={this.state.dataList} 
                                    onChangePage={this.onChangePage} 
                                    currentPageNo = {this.state.currentPageNo} />
                            }
                        </div>
                        
                    </div>
                </div>

                <Modal show={this.state.showModal} onHide={this.handleHideDeleteCategory}>
                    <Modal.Header closeButton>
                        <Modal.Title>Warning!!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="wr-msg">Are you sure you want to delete {this.state.editItem.categoryName} category?</div>
                        <div className="text-center">
                            <button className="btn btn-info btn-md" type="button" onClick={this.handleHideDeleteCategory}>No</button>
                            <button className="btn btn-primary btn-md" type="button" id="modal_button" onClick={this.deleteCategory}>Yes</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <AddEditCategoryDialog
                    isEdit={this.state.isEdit}
                    showCreateDialog={this.state.showCreateDialog}
                    handleCreateDialogClose={this.handleCreateDialogClose}
                    editItem={this.state.editItem}
                />
            </Router>
        );
    }
}
export default Categories;