import React, { Component } from 'react';
import * as Constants from '../../global/constants';
import Sidebar from '../../layoutsComponent/sidebar';
import Pagination from '../../pagesComponent/uiComponents/pagination';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import Modal from 'react-bootstrap-modal';
import ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as Api from '../../global/apiendpoints';
import { isEmptyArray, isEmptyVariable, 
    getLocalStorageVariables, getLocalDateOnlyFromUTC, 
    getUTCDateTimeFromLocal } from "../../global/commonFunctions";
import AddEditUserDialog from "./addEditUser";

const userDetails = getLocalStorageVariables();

class Appusers extends Component {
    constructor(props) {
        super(props);

        let userType = Constants.USER_TYPE_ADMIN;
        if(this.props.location.pathname.includes("admins")){
            userType = Constants.USER_TYPE_PANELADMIN;
        }

        this.state = {
            userType:userType,
            currentPageNo: 1,
            dataList: [],
            totalCount:0,
            errors:[],
            name:'',
            email:'',
            status:'',
            platform:'',
            searchFilterFromDate: '',
            searchFilterToDate: '',
            phoneNo:'',

            showModal:false,
            changeUserId:'',
            changeUserStatus:'',

            showCreateDialog:false,
            isEdit:false,
            editItem:"",
        };
    }

    componentDidMount() {
        this.getInitData();
    }

    handleCreateDialogClose = (reloadFlag) =>{
        this.setState({
            showCreateDialog:false
        },()=>{
            if(reloadFlag){
                this.getUsersList();
            }
        });
    }

    handleShowCreateDialog = () => {
        this.setState({
            showCreateDialog:true,
            isEdit:false,
            editItem:"",
        });
    }

    handleEditCategory = (item) =>{
        this.setState({
            showCreateDialog:true,
            isEdit:true,
            editItem:item
        });

    }

    handleSearch = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSearchFromDate = (e) => {
        this.setState({ searchFilterFromDate: e });
    }

    handleSearchToDate = (e) => {
        this.setState({ searchFilterToDate: e });
    }

    search() {
        var error_flag = false;
        let errors = {};
        if (
            isEmptyVariable(this.state.name) &&
            isEmptyVariable(this.state.email) &&
            isEmptyVariable(this.state.status) &&
            isEmptyVariable(this.state.platform) &&
            isEmptyVariable(this.state.phoneNo) &&
            isEmptyVariable(this.state.searchFilterFromDate) &&
            isEmptyVariable(this.state.searchFilterToDate) 
            )
        {
            error_flag = true;
            errors['search'] = "Please select atleast one filter!";
            setTimeout(() => {
                this.setState({errors:{}});
           },Constants.WRNG_MSG_TIMEOUT);  
        }
        if(this.state.phoneNo !== "")
        {
            var val = this.state.phoneNo;
            var len	=	val.length;
            var reg = /^\d+$/;
            if( !reg.test( val ) )
            {
                error_flag = true;
                errors["search"] = "Please enter valid phone no!";
                setTimeout(() => {
                    this.setState({errors:{}});
               },Constants.WRNG_MSG_TIMEOUT);
            }
            else
            {
                if(len < 10)
                {
                    error_flag = true;
                    errors["search"] = "Please enter valid phone no!";
                    setTimeout(() => {
                        this.setState({errors:{}});
                   },Constants.WRNG_MSG_TIMEOUT);
                }
            }
        }
        if (error_flag) {
            this.setState({
                errors: errors
            });
            return error_flag;
        }
        else {
            this.setState({currentPageNo:1},()=>{
                this.getUsersList();
            });
        }
    }
    
    onEnterBtnPress = (e) => {
        let code = e.keyCode || e.which;
        if(code === 13){
            this.search();
        }
    }

    clearFilter() {
        this.setState({
            name:'',
            email:'',
            status:'',
            platform:'',
            phoneNo:'',
            searchFilterFromDate: '',
            searchFilterToDate: '',
        },()=>{
            this.getUsersList();
        });
        
    }

    statusChange = (item)=>{
        let newStatus = Constants.USER_STATUS_ACTIVE;

        if(item.status === Constants.USER_STATUS_ACTIVE){
            newStatus = Constants.USER_STATUS_BLOCKED;
        }

        this.setState({
            changeUserId:item.userId,
            changeUserStatus:newStatus,
            showModal:true
        });
    }

    closeModal = () =>{
        this.setState({ showModal: false });
    }

    getInitData = () => {
        Promise.all([fetch(Api.GET_USERS,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(
                    { 
                        pageNo: this.state.currentPageNo,
                        resultsize:Constants.RESULT_SIZE,
                        userType: this.state.userType,
                        email:userDetails.email,
                        accessToken:userDetails.accessToken
    
                    })
                })
            ])
            .then(([res1]) => { 
                return Promise.all([res1.json()]) 
            })
            .then(([res1]) => {
                if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState(
                    {
                        dataList:res1.data.result,
                        totalCount:res1.data.count,
                    });
                }
            });
    }

    getUsersList() {
        fetch(Api.GET_USERS,
            {
                method: "POST",
                mode: 'cors',
                body: new URLSearchParams({ 
                    pageNo: this.state.currentPageNo, 
                    resultsize:Constants.RESULT_SIZE,
                    userType: this.state.userType,
                    name:this.state.name,
                    searchEmail:this.state.email,
                    status:this.state.status,
                    platform:this.state.platform,
                    phoneNo:this.state.phoneNo,
                    searchFilterFromDate: getUTCDateTimeFromLocal(this.state.searchFilterFromDate),
                    searchFilterToDate: getUTCDateTimeFromLocal(this.state.searchFilterToDate,true),
                    email:userDetails.email,
                    accessToken:userDetails.accessToken
                }) 
            })
            .then(response => { return response.json(); })
            .then(res1 => {
                if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState(
                    {
                        dataList:res1.data.result,
                        totalCount:res1.data.count,
                    });
                }
            });
    };

    onChangePage = (page) => {
        // alert(page);
        // update state with new page of items
        if(page != this.state.currentPageNo){
            fetch(Api.GET_USERS,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    pageNo: page, 
                    resultsize:Constants.RESULT_SIZE,
                    userType: this.state.userType,
                    name:this.state.name,
                    searchEmail:this.state.email,
                    status:this.state.status,
                    platform:this.state.platform,
                    phoneNo:this.state.phoneNo,
                    searchFilterFromDate: getUTCDateTimeFromLocal(this.state.searchFilterFromDate),
                    searchFilterToDate: getUTCDateTimeFromLocal(this.state.searchFilterToDate,true),
                    email:userDetails.email,
                    accessToken:userDetails.accessToken
                })
            })
            .then(response => { return response.json(); } )
            .then(res1 =>
            {
                if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState(
                    {
                        dataList:res1.data.result,
                        totalCount:res1.data.count,
                        currentPageNo:page,
                    });
                }
            });
        }
    }

    changeUserStatus =() =>{
        fetch(Api.CHANGE_USER_STATUS,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(
                {
                   userId:this.state.changeUserId,
                   status:this.state.changeUserStatus,
                   email:userDetails.email,
                   accessToken:userDetails.accessToken
                })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.RESPONSE_CODE_ACCESSTOKEN_INVALID)
            {
                localStorage.clear();
                window.location = "/";
            }
            else
            {
                this.setState({
                    changeUserId:"",
                    changeUserStatus:"",
                    showModal:false
                },()=>{
                    this.getUsersList();
                });
            }
        });
    }

    render() {
        return (
            <Router>
                <Sidebar />
                <div className="main-panel">
                    <nav className="header navbar">
                        <div className="header-inner">
                            <div className="navbar-item navbar-spacer-right brand hidden-lg-up">
                                <a href="javascript:;" data-toggle="sidebar" className="toggle-offscreen">
                                    <i className="material-icons">menu</i>
                                </a>
                            </div>
                            <a className="navbar-item navbar-spacer-right navbar-heading hidden-md-down" href="javascript:void(0);">
                                <span className="tophead-txt">App Users >> View List</span>
                                <span className="tophead-txt pull-right"></span>
                            </a>
                        </div>
                    </nav>

                    <div className="main-content">
                        <div className="content-view">

                            <div className="card">
                                <div className="sec-t-container m-b-2">
                                    <h4 className="card-title">Search Filters</h4>
                                </div>
                                <div className="card-block">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <b>Name</b>
                                            <fieldset className="form-group">
                                                <input placeholder="Name" className="srh-fld" name="name" onChange={this.handleSearch} value={this.state.name} onKeyPress={this.onEnterBtnPress}/>
                                            </fieldset>
                                        </div>
                                        <div className="col-lg-3">
                                            <b>Email</b>
                                            <fieldset className="form-group">
                                                <input placeholder="Email" className="srh-fld" name="email" onChange={this.handleSearch} value={this.state.email} onKeyPress={this.onEnterBtnPress}/>
                                            </fieldset>
                                        </div>
                                        <div className="col-lg-3">
                                            <b>Phone No</b>
                                            <fieldset className="form-group">
                                                <input placeholder="Phone No" className="srh-fld" name="phoneNo" onChange={this.handleSearch} value={this.state.phoneNo} onKeyPress={this.onEnterBtnPress}/>
                                            </fieldset>
                                        </div>
                                        <div className="col-lg-3">
                                            <b>Platform</b>
                                            <fieldset className="form-group">
                                                <select name="platform" className="srh-fld" onChange={this.handleSearch} value={this.state.platform}>
                                                    <option value="">--select--</option>
                                                    <option value={Constants.USER_PLATFORM_EMAIL}>{Constants.USER_PLATFORM_EMAIL}</option>
                                                    <option value={Constants.USER_PLATFORM_Google}>{Constants.USER_PLATFORM_Google}</option>
                                                    <option value={Constants.USER_PLATFORM_Facebook}>{Constants.USER_PLATFORM_Facebook}</option>
                                                </select>
                                            </fieldset>
                                        </div> 
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <b>Status</b>
                                            <fieldset className="form-group">
                                                <select name="status" className="srh-fld" onChange={this.handleSearch} value={this.state.status}>
                                                    <option value="">--select--</option>
                                                    <option value={Constants.USER_STATUS_ACTIVE}>{Constants.USER_STATUS_ACTIVE}</option>
                                                    <option value={Constants.USER_STATUS_BLOCKED}>{Constants.USER_STATUS_BLOCKED}</option>
                                                </select>
                                            </fieldset>
                                        </div> 
                                        <div className="col-lg-3">
                                            <b>Registered From Date</b>
                                            <DatePicker
                                                name="searchFromDate"
                                                selected={this.state.searchFilterFromDate}
                                                onChange={this.handleSearchFromDate}
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <b>Registered To Date</b>
                                            <DatePicker
                                                name="searchToDate"
                                                selected={this.state.searchFilterToDate}
                                                onChange={this.handleSearchToDate}
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                        <div className="col-lg-3" style={{float:'right'}}>
                                            <b>&nbsp;</b>
                                            <div className="srch-fltr-btns">
                                                <button type="button" className="btn btn-primary" onClick={this.search.bind(this)}><i className="fa fa-search" aria-hidden="true"></i> <span className="filterText">Search</span></button>
                                                <button type="button" className="btn btn-danger" onClick={this.clearFilter.bind(this)}><i className="fa fa-times-circle-o" aria-hidden="true"></i><span className="filterText">&nbsp;Clear</span></button>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !isEmptyVariable(this.state.errors.search) && <span id="error_msg" className="err_msg">{this.state.errors.search}</span>
                                    }
                                </div>
                            </div>

                            <div className="card">
                                <div className="sec-t-container m-b-2 heading-add-btn">
                                    <h4 className="card-title">{this.state.userType === Constants.USER_TYPE_PANELADMIN?"Admins":"App Users"}</h4>
                                    {
                                        this.state.userType === Constants.USER_TYPE_PANELADMIN &&
                                        <a className="btn btn-primary add-new-btn" onClick={this.handleShowCreateDialog}>
                                            <i className="material-icons">control_point</i>
                                            <span className="filterText">Add User</span>
                                        </a>
                                    }
                                </div>
                                <div className="card-block">
                                    <div id="clientlist-wait" className="loader-login">
                                        <img src="/assets/images/spinner.GIF" width="64" height="64" />
                                    </div>
                                    <div className="">
                                        <table className="table m-b-0">
                                            <thead>
                                                <tr>
                                                    <th width={"5%"}>No</th>
                                                    <th>Name</th>
                                                    {
                                                        this.state.userType !== Constants.USER_TYPE_PANELADMIN &&
                                                        <th>Orders</th>
                                                    }
                                                    <th>Contact</th>
                                                    {
                                                        this.state.userType !== Constants.USER_TYPE_PANELADMIN &&
                                                        <th>Platform</th>
                                                    }
                                                    <th>Registration Date</th>
                                                    <th>Status</th>
                                                    <th width={"5%"}>Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    !isEmptyArray(this.state.dataList) &&
                                                    this.state.dataList.map(function (item, index) {
                                                        return <tr>
                                                            <td>{((this.state.currentPageNo - 1) * Constants.RESULT_SIZE) + (++index)}</td>
                                                            <td>
                                                                <a href={"/userdetails/"+ item.userId}>
                                                                {item.name}
                                                                </a>
                                                            </td>
                                                            {
                                                                this.state.userType !== Constants.USER_TYPE_PANELADMIN &&
                                                                <td>{item.orderCount}</td>
                                                            }
                                                            <td>{item.email}<br/><strong>Phone: </strong>{(item.phoneNo && item.phoneNo !=="undefined" && item.phoneNo !=="")?item.phoneNo:"N/A"}</td>
                                                            {
                                                                this.state.userType !== Constants.USER_TYPE_PANELADMIN &&
                                                                <td>{item.platform}</td>    
                                                            }
                                                            <td>
                                                                {getLocalDateOnlyFromUTC(item.createDate)}
                                                            </td>
                                                            <td>{item.status}</td>
                                                            <td>
                                                                {
                                                                    this.state.userType === Constants.USER_TYPE_PANELADMIN &&
                                                                    <a onClick={this.handleEditCategory.bind(this,item)}>
                                                                        <i className="material-icons">edit</i>
                                                                    </a>
                                                                }
                                                                <a onClick={this.statusChange.bind(this,item)} 
                                                                data-tip={item.status === Constants.USER_STATUS_ACTIVE?"Block":"Activate"}>
                                                                    <i className="material-icons">
                                                                        {item.status === Constants.USER_STATUS_ACTIVE?"lock":"check_circle"}
                                                                    </i>
                                                                </a>
                                                                <ReactTooltip />
                                                            </td>
                                                        </tr>;
                                                    }, this)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {
                                !isEmptyArray(this.state.dataList) && (this.state.totalCount > Constants.RESULT_SIZE) && 
                                <Pagination 
                                    totalLength ={this.state.totalCount} 
                                    items={this.state.dataList} 
                                    onChangePage={this.onChangePage} 
                                    currentPageNo = {this.state.currentPageNo} />
                            }
                        </div>
                    </div>
                </div>

                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Warning!!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="wr-msg">Are you sure you want to {this.state.changeUserStatus === Constants.USER_STATUS_ACTIVE?"Activate":"Block"} this user?</div>
                        <div className="text-center">
                            <button className="btn btn-info btn-md" type="button" onClick={this.closeModal}>No</button>
                            <button className="btn btn-primary btn-md" type="button" id="modal_button" onClick={this.changeUserStatus}>Yes</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <AddEditUserDialog
                    isEdit={this.state.isEdit}
                    showCreateDialog={this.state.showCreateDialog}
                    handleCreateDialogClose={this.handleCreateDialogClose}
                    editItem={this.state.editItem}
                />
            </Router>
        );
    }
}
export default Appusers;