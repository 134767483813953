import React, { Component } from 'react';
import * as Constants from '../../global/constants';
import Sidebar from '../../layoutsComponent/sidebar';
import Pagination from '../../pagesComponent/uiComponents/pagination';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {
    BrowserRouter as Router
} from 'react-router-dom';
import * as Api from '../../global/apiendpoints';
import { isEmptyArray, isEmptyVariable, getLocalStorageVariables, getLocalDateFromUTC } from "../../global/commonFunctions";

const userDetails = getLocalStorageVariables();


class HireEnquiries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPageNo: 1,
            pageOfItems: [],
            totalCount:0,
            errors:{},

            searchFilterFromDate: '',
            searchFilterToDate: '',
            name:'',
            email:'',
            hirefor:'',
            status:''
        };
    }

    componentDidMount() {
        this.getInitData();
    }

     handleSearchFromDate = (e) => {
         this.setState({ searchFilterFromDate: e });
    }

     handleSearchToDate = (e) => {
         this.setState({ searchFilterToDate: e });
    }

     handleSearch = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    search() {
        var error_flag = false;
        let errors = {};
        if (

            isEmptyVariable(this.state.email)  &&
            isEmptyVariable(this.state.name)  &&
            isEmptyVariable(this.state.hirefor)  &&
            isEmptyVariable(this.state.status)  &&
            isEmptyVariable(this.state.searchFilterFromDate)  &&
            isEmptyVariable(this.state.searchFilterToDate)  
        )
        {
            error_flag = true;
            errors['search'] = "Please select atleast one filter!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),Constants.WRNG_MSG_TIMEOUT);  
        }
        if (error_flag) {
            this.setState({
                errors: errors
            });
            return error_flag;
        }
        else {
            this.setState({currentPageNo:1},()=>{
                this.getHireEnquiresList();
            });
        }
    }
    
    onEnterBtnPress = (e) => {
        let code = e.keyCode || e.which;
        if(code === 13){
            this.search();
        }
    }
     clearFilter() {
         this.setState({
            searchFilterFromDate: '',
            searchFilterToDate: '',
            name:'',
            email:'',
            hirefor:'',
            status:''
        },()=>{
            this.getHireEnquiresList();
        });
        
    }

    getInitData = () =>{
        Promise.all([fetch(Api.GET_HIRES,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(
                { 
                    email:userDetails.email,
                    accessToken:userDetails.accessToken
                })
            })
        ])
        .then(([res1]) => { 
            return Promise.all([res1.json()]) 
        })
        .then(([res1]) => {
            if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                localStorage.clear();
                window.location = "/";
            }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                this.setState({
                    pageOfItems:res1.data.result,
                    totalCount:res1.data.count,
                });
            }
            
      });
    }

    getHireEnquiresList() {

        fetch(Api.GET_HIRES,
        {
            method: "POST",
            mode: 'cors',
            body: new URLSearchParams(
            { 
                pageNo: this.state.currentPageNo, 
                searchFilterFromDate: this.state.searchFilterFromDate,
                searchFilterToDate: this.state.searchFilterToDate,
                name:this.state.name,
                email:this.state.email,
                hirefor:this.state.hirefor,
                status:this.state.status,
                email:userDetails.email,
                accessToken:userDetails.accessToken
            }) 
        })
        .then(response => { return response.json(); })
        .then(res1 => {
            if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                localStorage.clear();
                window.location = "/";
            }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                this.setState({
                    pageOfItems:res1.data.result,
                    totalCount:res1.data.count,
                });
            }
        });
    };

    onChangePage = (page) => {
        if(page != this.state.currentPageNo){
            fetch(Api.GET_HIRES,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(
                    {
                        pageNo: page, 
                        searchFilterFromDate: this.state.searchFilterFromDate,
                        searchFilterToDate: this.state.searchFilterToDate,
                        name:this.state.name,
                        email:this.state.email,
                        hirefor:this.state.hirefor,
                        status:this.state.status,
                        email:userDetails.email,
                        accessToken:userDetails.accessToken
                    })
            })
            .then(response => { return response.json(); } )
            .then(res1 =>
            {

                if(res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_EXPIRED_V4 ||
                    res1.responseCode === Constants.RESPONSE_CODE_ACCESS_TOKEN_INVALID_V4){
                    localStorage.clear();
                    window.location = "/";
                }else if(res1.responseCode === Constants.RESPONSE_CODE_SUCCESS){
                    this.setState({
                        pageOfItems:res1.data.result,
                        totalCount:res1.data.count,
                        currentPageNo:page,
                    });
                }
            });
        }
    }

    render() {
        return (
            <Router>
                <Sidebar />
                <div className="main-panel">
                    <nav className="header navbar">
                        <div className="header-inner">
                            <div className="navbar-item navbar-spacer-right brand hidden-lg-up">
                                <a href="javascript:;" data-toggle="sidebar" className="toggle-offscreen">
                                    <i className="material-icons">menu</i>
                                </a>
                            </div>
                            <a className="navbar-item navbar-spacer-right navbar-heading hidden-md-down" href="javascript:void(0);">
                                <span className="tophead-txt">Hire Enquiries >> View List</span>
                                <span className="tophead-txt pull-right"></span>
                            </a>
                        </div>
                    </nav>

                    <div className="main-content">
                        <div className="content-view">

                            <div className="card">
                                <div className="sec-t-container m-b-2"><h4 className="card-title">Search Filters</h4></div>
                                    <div className="card-block">

                                        <div className="row">
                                            <div className="col-lg-3">
                                                <b>Name</b>
                                                <fieldset className="form-group">
                                                    <input placeholder="Name" className="srh-fld" name="name" onChange={this.handleSearch} value={this.state.name} onKeyPress={this.onEnterBtnPress}/>
                                                </fieldset>
                                            </div>
                                            <div className="col-lg-3">
                                                <b>Email</b>
                                                <fieldset className="form-group">
                                                    <input placeholder="Email" className="srh-fld" name="email" onChange={this.handleSearch} value={this.state.email} onKeyPress={this.onEnterBtnPress}/>
                                                </fieldset>
                                            </div>
                                            <div className="col-lg-3">
                                                <b>Hire For</b>
                                                <fieldset className="form-group">
                                                    <select name="hirefor" className="srh-fld" onChange={this.handleSearch} value={this.state.hirefor}>
                                                    <option value="">--select--</option>
                                                    <option value="For a week">For a week</option>
                                                    <option value="For a month">For a month</option>
                                                    <option value="For more than a month">For more than a month</option>
                                                    <option value="For one day trail">For one day trail</option>
                                                </select>
                                                </fieldset>
                                            </div>
                                             <div className="col-lg-3">
                                                <b>Status</b>
                                                <fieldset className="form-group">
                                                <select name="status" className="srh-fld" onChange={this.handleSearch} value={this.state.status}>
                                                <option value="">--select--</option>
                                                <option value={Constants.HIRE_STATUS_UNREAD}>{Constants.HIRE_STATUS_UNREAD}</option>
                                                <option value={Constants.HIRE_STATUS_READ}>{Constants.HIRE_STATUS_READ}</option>
                                                </select>
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className ="row">
                                            <div className="col-lg-3">
                                                <b>From Date</b>
                                                <DatePicker
                                                    name="searchFromDate"
                                                    selected={this.state.searchFilterFromDate}
                                                    onChange={this.handleSearchFromDate}
                                                    dateFormat="dd-MM-yyyy"
                                                />
                                            </div>
                                            <div className="col-lg-3">
                                                <b>To Date</b>
                                                <DatePicker
                                                    name="searchToDate"
                                                    selected={this.state.searchFilterToDate}
                                                    onChange={this.handleSearchToDate}
                                                    dateFormat="dd-MM-yyyy"
                                                />
                                            </div>

                                            <div className="col-lg-3" style={{float:'right'}}>
                                                <b>&nbsp;</b>
                                                <div className="srch-fltr-btns">
                                                    <button type="button" className="btn btn-primary" onClick={this.search.bind(this)}><i className="fa fa-search" aria-hidden="true"></i> <span className="filterText">Search</span></button>
                                                    <button type="button" className="btn btn-danger" onClick={this.clearFilter.bind(this)}><i className="fa fa-times-circle-o" aria-hidden="true"></i><span className="filterText">&nbsp;Clear</span></button>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                        !isEmptyVariable(this.state.errors.search) && <span id="error_msg" className="err_msg">{this.state.errors.search}</span>
                                    }
                                    </div>
                                </div>


                            <div className="card">
                                <div className="sec-t-container m-b-2 addclnt-title"><h4 className="card-title">Hire Enquiries List</h4>
                                </div>
                                <div className="card-block">
                                    <div id="clientlist-wait" className="loader-login">
                                        <img src="/assets/images/spinner.GIF" width="64" height="64" />
                                    </div>
                                    <div className="">
                                        <table className="table m-b-0">
                                            <thead>
                                                <tr>
                                                    <th>Sl No</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Developer For</th>
                                                    <th>Hire For</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    !isEmptyArray(this.state.pageOfItems) &&
                                                    this.state.pageOfItems.map(function (item, index) {
                                                        return <tr>
                                                            <td>{((this.state.currentPageNo - 1) * Constants.RESULT_SET_SIZE) + (++index)}</td>
                                                            <td><a href={"/hiredetails/"+item.hireId}>{item.name}</a></td>
                                                            <td>{item.email}</td>
                                                            <td>{item.developerfor}</td>
                                                            <td>{item.hirefor}</td>
                                                            <td>{getLocalDateFromUTC(item.createDate)}</td>
                                                            <td>
                                                                <span className={item.status==="UNREAD"?"status-span":"free-span"}>
                                                                    {item.status}
                                                                </span>
                                                            </td>
                                                        </tr>;
                                                    }, this)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {
                                !isEmptyArray(this.state.pageOfItems) && (this.state.totalCount > Constants.RESULT_SET_SIZE) && 
                                <Pagination 
                                    totalLength ={this.state.totalCount} 
                                    items={this.state.pageOfItems} 
                                    onChangePage={this.onChangePage} 
                                    currentPageNo = {this.state.currentPageNo} />
                            }
                        </div>
                    </div>
                </div>
            </Router>
        );
    }
}
export default HireEnquiries;