import * as URLs from '../../urls';

export const BASE_URL_API = URLs.API_BASE_URL_PATH;

export const BACKEND_LOGIN = BASE_URL_API+"backendlogin";
export const GET_CATEGORIES = BASE_URL_API+"getcategoriesadmin";
export const DELETE_CATEGORY = BASE_URL_API+"deletecategory";
export const GET_HIRES = BASE_URL_API+"gethires";
export const GET_DASHBOARD = BASE_URL_API+"getdashboard";
export const GET_ORDERS_ADMIN = BASE_URL_API+"getordersadmin";
export const GET_USERS = BASE_URL_API+"getusers";
export const CREATE_USER = BASE_URL_API+"createuser";
export const UPDATE_USER = BASE_URL_API+"updateuser";
export const CHANGE_USER_STATUS = BASE_URL_API+"changeuserstatus";
export const CHANGE_PASSWORD = BASE_URL_API+"changepasswordadmin";
export const UPLOAD_PROFILE_IMAGE = BASE_URL_API+"uploadprofileimage";
export const ADD_CATEGORY = BASE_URL_API+"addcategoryadmin";
export const EDIT_CATEGORY = BASE_URL_API+"updatecategoryadmin";
export const CHECK_CATEGORY_URL = BASE_URL_API+"checkcategoryurl";
export const GET_TEMPLATES_ADMIN = BASE_URL_API+"gettemplatesadmin";
export const DOWNLOAD_ZIP = BASE_URL_API+"admindownloadzip";
export const UPLOAD_TEMPLATE = BASE_URL_API+"uploadtemplate";
export const UPLOAD_TEMPLATE_FEATURED_IMAGE = BASE_URL_API+"uploadtemplatefeaturedimage";
export const UPLOAD_TEMPLATE_PREVIEW_IMAGE = BASE_URL_API+"uploadtemplatepreviewimage";
export const UPLOAD_TEMPLATE_IMAGE = BASE_URL_API+"uploadtemplateimage";
export const UPLOAD_TEMP_IMAGE = BASE_URL_API+"uploadtempimage";
export const UPLOAD_TEMPLATE_ZIP = BASE_URL_API+"uploadtemplatezip";
export const EDIT_TEMPLATE = BASE_URL_API+"edittemplate";